import axios from 'axios';
import Parser from 'rss-parser';
import he from 'he';

const parser = new Parser({
  customFields: {
    item: ['headerImage'],
  },
});

export const getNewsFeed = async () => {
  try {
    const {data} = await axios.get<any>('https://changers.com/content/feed/');
    const feed = await parser.parseString(he.decode(data));

    return feed.items.map((item) => ({
      id: item.guid,
      title: item.title,
      headerImage: item.enclosure?.url,
      summary: item.contentSnippet,
      // @ts-ignore
      content: item['content:encoded'],
    }));
  } catch (error) {
    return [];
  }
};
