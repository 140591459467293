import {useCallback, useContext, useEffect} from 'react';
import {SettingsContext} from 'contexts/SettingsContext';
import {AuthContext} from 'contexts/AuthContext';

export const useUserLoader = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const {loadUserProfile, isLoadingUserProfile, errorLoadingUserProfile} =
    useContext(AuthContext);

  const initialLoadUserProfile = useCallback(async () => {
    await loadUserProfile({reload: false});
  }, [loadUserProfile]);

  useEffect(
    () => {
      if (organizationConfig?.name) {
        initialLoadUserProfile();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationConfig],
  );

  return {
    isLoadingUserProfile,
    errorLoadingUserProfile,
    loadUserProfile: initialLoadUserProfile,
  };
};
