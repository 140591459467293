import {useCallback} from 'react';

import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {APP_PERMISSIONS} from 'constants/app-features';
import {METERING_IMAGE_ERROR_CODES} from 'components/ClimateWidgets/Metering/components/Consumption/constants';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useNativeListener} from 'hooks/use-native-listener';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';

import {sendMessageToNativeApp} from 'services/native-api';
import {MeteringImageData, uploadMeteringImage} from 'services/metering';
import {CameraImageEvent} from 'types/NativeEvents';

interface UseImageRequestProps {
  onComplete: (imageData?: MeteringImageData) => any;
  forceUpload?: boolean;
}

const useImageRequest = (props: UseImageRequestProps) => {
  const {onComplete, forceUpload} = props;
  const {translate} = useTranslations();
  const toast = useToast();
  const [isPermDialogVbl, showPermDialog, hidePermDialog] = useBoolean();
  const [isProcessingImage, startProcessingImage, stopProcessingImage] =
    useBoolean(false);

  const handleCameraImageResponse = useCallback(
    async (event: CameraImageEvent) => {
      if (event.image) {
        try {
          startProcessingImage();

          const imageData = await uploadMeteringImage({
            image: event.image,
            force: forceUpload,
          });

          onComplete(imageData);
        } catch (error: any) {
          let errorKey =
            error.response?.data?.errorKey ||
            'sdk.web.meter.consumption.upload.error';

          const errorDetails = error.response?.data?._details;
          if (
            !Object.values(METERING_IMAGE_ERROR_CODES).includes(errorDetails)
          ) {
            return toast.error(translate(errorKey));
          }

          switch (errorDetails) {
            case METERING_IMAGE_ERROR_CODES.INVALID_IMAGE_UPLOADED:
              errorKey = 'sdk.web.meter.consumption.upload.error.invalid';
              break;
            case METERING_IMAGE_ERROR_CODES.INCORRECT_ORIENTATION:
              errorKey = 'sdk.web.meter.consumption.upload.error.orientation';
              break;
            case METERING_IMAGE_ERROR_CODES.UNABLE_TO_PROCESS_IMAGE:
              errorKey = 'sdk.web.meter.consumption.upload.error.unprocessed';
              break;
            default:
              break;
          }

          if (!forceUpload) {
            toast.error(translate(errorKey));
          }

          onComplete();
        } finally {
          stopProcessingImage();
        }
      } else if (event.missing_camera_permission) {
        showPermDialog();
      }
    },
    [
      forceUpload,
      showPermDialog,
      onComplete,
      startProcessingImage,
      stopProcessingImage,
      toast,
      translate,
    ],
  );

  const handlePermissionCheck = useCallback(
    (goToSettings: boolean) => {
      if (goToSettings) {
        const message = {
          type: 'request_permissions',
          permission: [APP_PERMISSIONS.CAMERA],
        };
        sendMessageToNativeApp({message});
      }

      return hidePermDialog();
    },
    [hidePermDialog],
  );

  const {startListening} = useNativeListener({
    callback: handleCameraImageResponse,
    event: NATIVE_EVENTS.CAMERA_IMAGE,
    preventListen: true,
    isOneTime: true,
  });

  const handleTakePhoto = useCallback(() => {
    startListening();
    const message = {type: NATIVE_MESSAGES.CAMERA_IMAGE};
    sendMessageToNativeApp({message});
  }, [startListening]);

  return {
    isPermDialogVbl,
    isProcessingImage,
    handlePermissionCheck,
    handleTakePhoto,
  };
};

export default useImageRequest;
