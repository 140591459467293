interface RetryOperationParams {
  maxRetryCount: number;
  maxSessionTime: number;
  operation: () => Promise<any>;
}

export const retryOperation = async ({
  maxRetryCount,
  maxSessionTime,
  operation,
}: RetryOperationParams) => {
  return new Promise((resolve) => {
    let isSuccessful = false;
    let hasRetried = false;

    const handleRetry = async () => {
      const newMaxRetryCount = maxRetryCount - 1;

      if (isSuccessful || hasRetried) {
        return;
      }

      if (newMaxRetryCount <= 0) {
        return resolve(false);
      }

      hasRetried = true;
      await retryOperation({
        maxRetryCount: newMaxRetryCount,
        maxSessionTime,
        operation,
      });
    };

    operation()
      .then(() => {
        isSuccessful = true;
        resolve(true);
      })
      .catch(() => {
        handleRetry();
      });

    setTimeout(async () => {
      handleRetry();
    }, maxSessionTime);
  });
};

export const setDelay = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};
