import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import {LeaderBoard, Team} from 'types/Team';

import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Leaderboard from './Leaderboard';
import 'swiper/swiper-bundle.css';

const Wrapper = styled.div`
  padding: 0 20px;
  height: 100%;

  .swiper-container {
    height: 100%;
  }
`;

const Nav = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 25px;
  left: 0;
  width: 100%;
`;

const Dot = styled.div<{isActive: boolean}>`
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    props.theme.components.leaderboard?.dotsColor ||
    props.theme.colors.primary};
  opacity: ${(props) => (props.isActive ? 1 : 0.4)};
  margin: 0 5px;
  border-radius: 100%;
`;

interface PreviousLeaderboardInterface {
  previousLeaderboards: Array<LeaderBoard>;
  isVisible: boolean;
  onClose: () => void;
  isOwnTeam?: ({team}: {team: Team}) => boolean;
}

const PreviousLeaderboardPrompt = (props: PreviousLeaderboardInterface) => {
  const {previousLeaderboards, isVisible, isOwnTeam, onClose} = props;
  const swiperRef = useRef<SwiperClass>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    setActiveSlideIndex(0);
  }, [isVisible]);

  return (
    <PromptWrapper
      isVisible={isVisible && !!previousLeaderboards?.length}
      title={''}
      onClose={onClose}>
      <Wrapper>
        <Swiper
          onInit={(swiper: SwiperClass) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            setActiveSlideIndex(swiper.activeIndex);
          }}
          watchSlidesVisibility
          spaceBetween={10}
          slidesPerView={'auto'}
          threshold={10}>
          {previousLeaderboards?.map((leaderboard, index) => (
            <SwiperSlide key={index}>
              <Leaderboard
                leaderboard={leaderboard}
                previousLeaderboard={previousLeaderboards?.[index + 1]}
                isOwnTeam={isOwnTeam}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Nav>
          {previousLeaderboards?.map((leaderboard, index) => (
            <Dot
              key={index}
              isActive={activeSlideIndex === index}
              onClick={() => swiperRef.current?.slideTo(index)}
            />
          ))}
        </Nav>
      </Wrapper>
    </PromptWrapper>
  );
};

export default PreviousLeaderboardPrompt;
