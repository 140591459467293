import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes/settings';
import {CACHE_KEYS} from 'constants/cache-keys';
import {SettingsContext} from 'contexts/SettingsContext';
import {usePermissionsCheck} from 'hooks/use-permissions-check';
import {useTranslations} from 'hooks/use-translations';
import {addCacheData, getCachedData} from 'services/cache';
import {sendMessageToNativeApp} from 'services/native-api';
import {addBoldText, addGlobalSquareCorners} from 'utils/theme';

import SettingsButton from 'components/Button/SettingsButton/SettingsButton';
import CopyText from 'components/Text/CopyText/CopyText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: #fcd921;
  padding: 10px 20px 20px;
  text-align: center;
  margin-bottom: 10px;

  ${addGlobalSquareCorners()}
`;

const ContentBox = styled.div`
  margin-bottom: 20px;
`;

const Title = styled(CopyText)`
  display: block;
  margin-bottom: 10px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Message = styled(CopyTextSm)`
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const TrackingWarning = (props: any) => {
  const {history} = props;
  const {translate} = useTranslations();
  const {appName} = useContext(SettingsContext);
  const {
    isMotionTrackingEnabled,
    permissionTitleKey,
    permissionMessageKey,
    hasAllPermissions,
    getMissingPermissions,
  } = usePermissionsCheck();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const cachedTrackingWarning = getCachedData({
      key: CACHE_KEYS.TRACKING_WARNING,
    });
    const hasCachedData = Boolean(cachedTrackingWarning);
    if (hasCachedData) {
      setIsInitialized(true);
      return;
    }

    setTimeout(() => {
      setIsInitialized(true);
    }, 1500);
  }, []);

  const showTrackingWarning = useMemo(() => {
    return !isMotionTrackingEnabled || !hasAllPermissions;
  }, [isMotionTrackingEnabled, hasAllPermissions]);

  useEffect(() => {
    addCacheData({
      key: CACHE_KEYS.TRACKING_WARNING,
      data: showTrackingWarning,
    });
  }, [showTrackingWarning]);

  const handlePermissionsRequest = useCallback(() => {
    if (!isMotionTrackingEnabled) {
      history.push(routes.href);
      return;
    }

    const missingPermissions = getMissingPermissions();
    const message = {
      type: 'request_permissions',
      permission: missingPermissions,
    };
    sendMessageToNativeApp({message});
  }, [getMissingPermissions, isMotionTrackingEnabled, history]);

  return (
    <>
      {isInitialized && showTrackingWarning && (
        <Wrapper>
          <ContentBox>
            {!isMotionTrackingEnabled && (
              <Title>
                {translate('sdk.web.permission.activate.tracking', {
                  key: '{appName}',
                  value: appName,
                })}
              </Title>
            )}
            {isMotionTrackingEnabled && !hasAllPermissions && (
              <>
                <Title>
                  {translate(permissionTitleKey, {
                    key: '{appName}',
                    value: appName,
                  })}
                </Title>
                <Message>
                  {translate(permissionMessageKey, {
                    key: '{appName}',
                    value: appName,
                  })}
                </Message>
              </>
            )}
          </ContentBox>
          <SettingsButton onClick={handlePermissionsRequest}>
            {translate('sdk.web.permission.go.to.settings')}
          </SettingsButton>
        </Wrapper>
      )}
    </>
  );
};

export default withRouter(TrackingWarning);
