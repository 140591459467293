import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import {TeamContext} from 'contexts/TeamContext';
import {NotificationContext} from 'contexts/NotificationContext';
import {useOrganization} from 'hooks/use-organization';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {getMonthTranslation} from 'utils/date';
import {getTeamsFromTeamTypes} from 'utils/teams';
import {Team, TeamType} from 'types/Team';
import {TeamLeaderBoard} from 'services/teams';

import PreviousLeaderboardPrompt from 'components/ClimateWidgets/Leaderboard/PreviousLeaderboardPrompt';
import LeaderboardLink from 'components/ClimateWidgets/Leaderboard/components/LeaderboardLink';
import TeamSettings from './components/TeamSettings';
import LeaderboardWidget from './LeaderboardWidget';
import FullTeamLeaderboard from './FullTeamLeaderboard';

import bannerImg from './images/banner.svg';

const Cta = styled.div`
  text-align: center;
`;

const TITLE_TRANSLATION_KEY = 'sdk.web.teams.title';
const SUBTITLE_TRANSLATION_KEY = 'sdk.web.teams.subtitle';

type TeamLeaderboardProps = {
  leaderboard: TeamLeaderBoard;
  isLoading: boolean;
  onTeamChange: () => void;
  teamTypes: Array<TeamType>;
  showTeamName: boolean;
  index: number;
};

const TeamLeaderboard = (props: TeamLeaderboardProps) => {
  const {leaderboard, isLoading, onTeamChange, teamTypes, showTeamName, index} =
    props;
  const {addPrivateChannelListener} = useContext(NotificationContext);
  const {translate} = useTranslations();
  const {organizationConfig} = useOrganization();
  const {currentTeams, updateCurrentTeams} = useContext(TeamContext);
  const [initialLoad, setInitialLoad] = useState(true);

  const [
    isPreviousLeaderboardVisible,
    openPreviousLeaderboard,
    closePreviousLeaderBoard,
  ] = useBoolean();
  const [isFullTeamOpen, openFullTeam, closeFullTeam] = useBoolean();

  const teamTypeId = leaderboard.teamTypeId;
  const teams = leaderboard?.current?.teams || [];
  const previousTeams = leaderboard.previousLeaderboards[0]?.teams || [];

  // Get selected team from TeamContext
  const selectedTeam = currentTeams![teamTypeId];

  const leaderboardTranslations = organizationConfig.translations.leaderboard;
  const image =
    leaderboard.image ||
    organizationConfig.theme.components.leaderboards?.banner ||
    bannerImg;

  const allTeams = useMemo(
    () => getTeamsFromTeamTypes({teamTypes, teamTypeId}),
    [teamTypes, teamTypeId],
  );

  const {title, subtitle} = useMemo(() => {
    const month = getMonthTranslation({translate});
    const translateOptions = {
      key: '{month}',
      value: month,
    };

    const indexTranslations = leaderboardTranslations?.leaderboards?.find(
      ({index: _index}: {index: number}) => index === _index,
    );

    const title = indexTranslations?.title
      ? translate(indexTranslations.title, translateOptions)
      : showTeamName
      ? `${leaderboard.name} ${month}`
      : translate(
          leaderboardTranslations?.title || TITLE_TRANSLATION_KEY,
          translateOptions,
        ) || translate(TITLE_TRANSLATION_KEY, translateOptions);

    const subtitle = indexTranslations?.subtitle
      ? translate(indexTranslations.subtitle, translateOptions)
      : translate(
          leaderboardTranslations?.subtitle || SUBTITLE_TRANSLATION_KEY,
        ) || translate(SUBTITLE_TRANSLATION_KEY);

    return {title, subtitle};
  }, [translate, index, showTeamName, leaderboard, leaderboardTranslations]);

  useEffect(
    () => {
      // Call team change if selected team changes only after initial load
      if (initialLoad) {
        return;
      }

      if (selectedTeam?.id) {
        onTeamChange();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTeam],
  );

  useEffect(
    () => {
      const event = 'LeaderboardUpdated';
      const channel = addPrivateChannelListener!({
        event,
        callback: onTeamChange,
      });

      return () => {
        channel?.unbind(event);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [addPrivateChannelListener],
  );

  useEffect(
    () => {
      // Update team context with selected team from the API if there's no team selected in context
      if (selectedTeam?.id) {
        return;
      }

      const existingTeam = teams.find((team: Team) => !!team.users?.me);
      if (existingTeam) {
        updateCurrentTeams!({
          teamTypeId,
          team: existingTeam,
        });
        setInitialLoad(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams, teamTypeId],
  );

  const isOwnTeam = useCallback(
    ({team}: {team: Team}) => !!selectedTeam && selectedTeam.id === team.id,
    [selectedTeam],
  );

  const handleTeamSettingsClose = useCallback(
    (joinedTeam?: boolean) => {
      if (joinedTeam) {
        // Re-fetch leaderboard after 5s after API cache must have been cleared
        setTimeout(onTeamChange, 5000);
      }
    },
    [onTeamChange],
  );

  if ((!isLoading && !teams.length) || !leaderboard.current) {
    return null;
  }

  return (
    <>
      <LeaderboardWidget
        title={title}
        subtitle={subtitle}
        image={image}
        isLoading={isLoading && initialLoad}
        isOwnTeam={isOwnTeam}
        teams={teams}
        challengeType={leaderboard.current.challengeType}>
        <Cta>
          <TeamSettings
            leaderboard={leaderboard}
            teamTypes={teamTypes}
            onClose={handleTeamSettingsClose}
          />
          {!!previousTeams.length && (
            <LeaderboardLink onClick={openPreviousLeaderboard}>
              {translate('sdk.web.teams.show.previous.results')}
            </LeaderboardLink>
          )}
          {allTeams.length > teams.length && (
            <LeaderboardLink onClick={openFullTeam}>
              {translate('sdk.web.teams.show.all')}
            </LeaderboardLink>
          )}
        </Cta>
      </LeaderboardWidget>
      <PreviousLeaderboardPrompt
        isVisible={isPreviousLeaderboardVisible}
        previousLeaderboards={leaderboard.previousLeaderboards}
        onClose={closePreviousLeaderBoard}
      />
      <FullTeamLeaderboard
        isVisible={isFullTeamOpen}
        title={title}
        image={image}
        teamTypeId={teamTypeId}
        challengeId={leaderboard.current.challengeId}
        challengeType={leaderboard.current.challengeType}
        isOwnTeam={isOwnTeam}
        onClose={closeFullTeam}
      />
    </>
  );
};
export default TeamLeaderboard;
