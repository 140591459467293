import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
// @ts-ignore
import Toggle from 'react-toggle';

import routes from 'config/routes/settings';
import {CACHE_KEYS} from 'constants/cache-keys';
import {ActivityContext} from 'contexts/ActivityContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {useOrganization} from 'hooks/use-organization';
import {useBoolean} from 'hooks/utils/use-boolean';
import {usePermissionsCheck} from 'hooks/use-permissions-check';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useFetcher} from 'hooks/use-fetcher';
import {getMobilitySettings} from 'services/mobility-settings';
import {sendMessageToNativeApp} from 'services/native-api';
import {MobilitySettings} from 'types/Mobility';
import {addBoldText, addGlobalBorderColor} from 'utils/theme';
import {usesElectricity} from 'pages/Settings/MobilitySettings/utils';

import LocationDialogBox from '../LocationDialogBox/LocationDialogBox';
import DialogBox from 'components/DialogBox/DialogBox';
import CopyText from 'components/Text/CopyText/CopyText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import {ReactComponent as NextIcon} from './icons/next.svg';
import {ReactComponent as InfoIcon} from './icons/info.svg';

import 'react-toggle/style.css';
import './toggle.css';

const Wrapper = styled.div`
  background: ${(props) =>
    props.theme.components.settings.bgColor || props.theme.colors.secondary};
  width: 100%;
  padding: 20px 0;
  flex-grow: 1;
`;

const ItemLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:first-of-type {
    & > div {
      padding-top: 0;
    }
  }
`;

const Item = styled.div`
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.theme.components.settings.itemBorderColor || '#fff6'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  ${addGlobalBorderColor()}
`;

const Label = styled(CopyText)<{light?: any}>`
  display: inline-block;
  font-weight: ${(props) => (props.light ? '400' : undefined)};
  color: ${(props) =>
    props.theme.components.settings?.labelColor || props.theme.colors.black};

  ${(props) =>
    props.light
      ? ''
      : addBoldText({
          props,
        })}
`;

const TrackingLabel = styled(Label)<{light?: any}>`
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle;
`;

const Icon = styled.div`
  width: 17px;
  fill: ${(props) =>
    props.theme.components.settings.itemCaretColor || props.theme.colors.black};
  stroke: ${(props) =>
    props.theme.components.settings.itemCaretColor || props.theme.colors.black};
`;

const InlineIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
`;

const ToggleWrapper = styled.div`
  fill: ${(props) => props.theme.colors.black};
`;

const Settings = () => {
  const {translate} = useTranslations();
  const {isTreePlantingActive} = useContext(ActivityContext);
  const {organizationConfig, appName} = useContext(SettingsContext);
  const {
    lastTransmitted,
    isNotificationEnabled,
    setIsNotificationEnabled,
    setIsMotionTrackingEnabled,
  } = useContext(SettingsContext);

  const {
    isMotionTrackingEnabled,
    hasAllPermissions,
    hasNotifications: hasPushNotifications,
    getMissingPermissions,
  } = usePermissionsCheck();

  const {userProfile, reloadUserProfile} = useUserProfile();
  const {
    hasUserAccount,
    hasNewFAQ,
    hasLotteryForm,
    hasBambooCoin,
    hasNotifications,
    hasWidgetCustomization,
    hasVehicleSettings,
    hasSocioDemoData,
    hasGNUTaler,
  } = useOrganization();

  const [
    isNotificationDialogOpen,
    openNotificationDialog,
    closeNotificationDialog,
  ] = useBoolean(false);
  const [isTrackingDialogBoxVisible, setIsTrackingDialogBoxVisible] =
    useState(false);
  const [isLocationDialogVisible, setIsLocationDialogVisible] = useState(false);

  useEffect(
    () => {
      reloadUserProfile();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {data: mobilitySettings} = useFetcher<MobilitySettings>({
    fetcher: getMobilitySettings,
    key: CACHE_KEYS.MOBILITY_SETTINGS,
  });

  const onRequestForGPS = () => {
    setIsLocationDialogVisible(true);
  };

  const toggleNotifications = (enabled: boolean) => {
    const message = {
      type: 'notification',
      enabled,
    };
    setIsNotificationEnabled(enabled);
    sendMessageToNativeApp({message});
  };

  const handleNotificationsChange = (e: any) => {
    if (e.target.checked) {
      toggleNotifications(true);
      return;
    }

    openNotificationDialog();
  };

  const handleNotificationConfirmation = (enableNotifications: boolean) => {
    closeNotificationDialog();
    if (enableNotifications) {
      toggleNotifications(!enableNotifications);
    }
  };

  const toggleTracking = (enable: boolean) => {
    const message = {
      type: enable ? 'enable_source' : 'disable_source',
      source_id: 11,
    };
    setIsMotionTrackingEnabled(enable);
    sendMessageToNativeApp({message});
  };

  const onTrackingChange = (e: any) => {
    if (e.target.checked) {
      toggleTracking(true);
      return;
    }

    setIsTrackingDialogBoxVisible(true);
  };

  const onTrackingDialogBoxConfirmation = (disableTracking: boolean) => {
    setIsTrackingDialogBoxVisible(false);
    if (disableTracking) {
      toggleTracking(!disableTracking);
    }
  };

  const onLocationDialogBoxConfirmation = (requestForLocation: boolean) => {
    if (requestForLocation) {
      const message = {
        type: 'request_permissions',
        permission: getMissingPermissions(),
      };
      sendMessageToNativeApp({message});
    }

    setIsLocationDialogVisible(false);
  };

  return (
    <Wrapper>
      {hasUserAccount && (
        <ItemLink to={routes.ACCOUNT.href}>
          <Item>
            <Label>{translate('sdk.web.settings.profile.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {hasSocioDemoData && (
        <ItemLink to={routes.SOCIO_DEMO_DATA.href}>
          <Item>
            <Label>{translate('sdk.web.settings.sdd.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {userProfile.organisation.description_enabled && (
        <ItemLink to={routes.ABOUT.href}>
          <Item>
            <Label>
              {translate(organizationConfig.translations.aboutTokenLabel, {
                key: '{appName}',
                value: appName,
              })}
            </Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {hasBambooCoin && (
        <ItemLink to={routes.BAMBOO_COIN.href}>
          <Item>
            <Label>{translate('sdk.web.settings.bamboo.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {hasLotteryForm && (
        <ItemLink to={routes.LOTTERY_FORM.href}>
          <Item>
            <Label>{translate('sdk.web.settings.lots.form.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {isTreePlantingActive && (
        <ItemLink to={routes.ABOUT_TREE_PLANTING.href}>
          <Item>
            <Label>{translate('sdk.web.settings.treePlanting.about')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {hasWidgetCustomization && (
        <ItemLink to={routes.WIDGETS_CUSTOMIZATION.href}>
          <Item>
            <Label>{translate('sdk.web.settings.widgets.custom.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {(userProfile.organisation.faq_enabled || hasNewFAQ) && (
        <ItemLink to={routes.FAQ.href}>
          <Item>
            <Label>{translate('sdk.web.settings.faq.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      <Item>
        <Label>{translate('sdk.web.settings.last.data.sync')}</Label>
        <Label light>
          <CopyTextSm>{lastTransmitted}</CopyTextSm>
        </Label>
      </Item>
      <Item>
        <Label>
          {translate('sdk.web.settings.measure.distances.automatically')}
        </Label>
        <ToggleWrapper>
          <Toggle
            checked={isMotionTrackingEnabled}
            onChange={onTrackingChange}
            icons={false}
          />
        </ToggleWrapper>
      </Item>
      {hasPushNotifications && (
        <Item>
          <Label>{translate('sdk.web.settings.notifications.allow')}</Label>
          <ToggleWrapper>
            <Toggle
              checked={isNotificationEnabled}
              onChange={handleNotificationsChange}
              icons={false}
            />
          </ToggleWrapper>
        </Item>
      )}
      {hasNotifications && hasPushNotifications && isNotificationEnabled && (
        <ItemLink to={routes.NOTIFICATIONS.href}>
          <Item>
            <Label>{translate('sdk.web.settings.notifs')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      <Item>
        <Label>{translate('sdk.web.settings.tracking.status')}</Label>
        <Label>
          <TrackingLabel light>
            <CopyTextSm>
              {hasAllPermissions && isMotionTrackingEnabled
                ? translate('sdk.web.settings.tracking.active')
                : translate('sdk.web.settings.tracking.inactive')}
            </CopyTextSm>
          </TrackingLabel>
          {!hasAllPermissions && (
            <InlineIcon onClick={onRequestForGPS}>
              <InfoIcon />
            </InlineIcon>
          )}
        </Label>
      </Item>
      {hasVehicleSettings && (
        <>
          <ItemLink to={routes.VEHICLE_SETTINGS.href}>
            <Item>
              <Label>{translate('sdk.web.settings.vehicle.title')}</Label>
              <Icon>
                <NextIcon />
              </Icon>
            </Item>
          </ItemLink>
          {usesElectricity(mobilitySettings?.fuel_type) && (
            <ItemLink to={routes.E_CAR_RATING.href}>
              <Item>
                <Label>{translate('sdk.web.settings.eCar')}</Label>
                <Icon>
                  <NextIcon />
                </Icon>
              </Item>
            </ItemLink>
          )}
          <ItemLink to={routes.CARPOOLING_SETTING.href}>
            <Item>
              <Label>{translate('sdk.web.settings.carpool')}</Label>
              <Icon>
                <NextIcon />
              </Icon>
            </Item>
          </ItemLink>
        </>
      )}

      {hasGNUTaler && (
        <ItemLink to={routes.GNU_TALER_WALLET.href}>
          <Item>
            <Label>{translate('sdk.web.gnu.taler.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}

      {userProfile.organisation.privacy_policy_enabled && (
        <ItemLink to={routes.PRIVACY.href}>
          <Item>
            <Label>{translate('sdk.web.settings.privacy.policy.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      {userProfile.organisation.terms_of_service_enabled && (
        <ItemLink to={routes.TERMS.href}>
          <Item>
            <Label>{translate('sdk.web.settings.terms.title')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </ItemLink>
      )}
      <ItemLink to={routes.CONTACT.href}>
        <Item>
          <Label>{translate('sdk.web.settings.contact.title')}</Label>
          <Icon>
            <NextIcon />
          </Icon>
        </Item>
      </ItemLink>
      <ItemLink to={routes.DEVICE_INFO.href}>
        <Item>
          <Label>{translate('sdk.web.settings.device.info.title')}</Label>
          <Icon>
            <NextIcon />
          </Icon>
        </Item>
      </ItemLink>
      {isTrackingDialogBoxVisible && (
        <DialogBox
          onConfirmation={onTrackingDialogBoxConfirmation}
          promptMessage={translate('sdk.web.settings.tracking.end')}
          yesText={translate('sdk.web.dialog.box.ok')}
          noText={translate('sdk.web.dialog.box.cancel')}
        />
      )}
      {isNotificationDialogOpen && (
        <DialogBox
          onConfirmation={handleNotificationConfirmation}
          promptMessage={translate('sdk.web.settings.notifications.cancel')}
          yesText={translate('sdk.web.dialog.box.ok')}
          noText={translate('sdk.web.dialog.box.cancel')}
        />
      )}
      {isLocationDialogVisible && (
        <LocationDialogBox onConfirmation={onLocationDialogBoxConfirmation} />
      )}
    </Wrapper>
  );
};

export default Settings;
