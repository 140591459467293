export enum TRANSFER_TYPE {
  SEND = 'send',
  RECEIVE = 'receive',
}

export enum TRANSACTION_STATUS {
  PENDING = 'pending',
  DONE = 'done',
  EXPIRED = 'expired',
}

export type TalerTransaction = {
  id: number
  user_id: number
  amount: string
  type: TRANSFER_TYPE
  status: TRANSACTION_STATUS
  taler_transaction_id: string
  taler_uri: any
  share_uri: any
  qr_code_url: string
  wallet_transaction_id: any
  initiate_json: {
    err: any
    stderr: string
    stdout: {
      pursePub: string
      mergePriv: string
      contractPriv: string
      transactionId: string
      exchangeBaseUrl: string
    }
  }
  transaction_json: {
    err: any
    stderr: string
    stdout: {
      info: {
        summary: string
        expiration: {
          t_s: number
        }
      }
      type: string
      txState: {
        major: string
        minor: string
      }
      amountRaw: string
      timestamp: {
        t_s: number
        off_us: number
      }
      txActions: Array<string>
      transactionId: string
      amountEffective: string
      exchangeBaseUrl: string
    }
  }
  created_at: string
  updated_at: string
  deleted_at: any
}
