import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {useBoolean} from 'hooks/utils/use-boolean';
import {TeamType} from 'types/Team';
import {getTeamsFromTeamTypes} from 'utils/teams';

import {useTeams} from 'components/ClimateWidgets/Leaderboard/hooks/useTeams';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Button from 'components/Button/Button';
import TeamSelection from 'components/ClimateWidgets/Leaderboard/TeamSelection/TeamSelection';
import CtaLink from 'components/CtaLink/CtaLink';
import DialogBox from 'components/DialogBox/DialogBox';

import teamImg from 'components/ClimateWidgets/Leaderboard/images/team-form.svg';

const Content = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const TeamContent = styled.div`
  width: 100%;
`;

const TeamSelect = styled.div`
  display: block;
  padding: 10% 0 2%;
`;

const Image = styled.img`
  display: block;
  margin-top: 10%;
  margin-bottom: 15%;
  max-width: 100%;
`;

const ButtonContainer = styled.div`
  display: block;
  margin-bottom: 20px;
`;

const Link = styled(CtaLink)`
  margin-bottom: 0;
`;

const Footer = styled.div`
  width: 100%;
`;

interface TeamSettingsPromptProps {
  isVisible: boolean;
  teamTypeId: number;
  teamTypes: Array<TeamType>;
  onClose: (joinedTeam?: boolean) => void;
}

const TeamSettingsPrompt = (props: TeamSettingsPromptProps) => {
  const {isVisible, onClose, teamTypeId, teamTypes} = props;
  const {translate} = useTranslations();
  const [isLeaveTeamOpen, openLeaveTeam, closeLeaveTeam] = useBoolean(false);
  const [isDeleting, startDeleting, stopDeleting] = useBoolean(false);

  const teams = useMemo(
    () => getTeamsFromTeamTypes({teamTypes, teamTypeId}),
    [teamTypes, teamTypeId],
  );

  const {
    title,
    isSubmitting,
    currentTeam,
    handleTeamChange,
    handleJoinTeam,
    handleLeaveTeam,
    ErrorBlock,
  } = useTeams({teamTypeId, teams});

  const {organizationConfig} = useOrganization();
  const teamsConfig = organizationConfig.theme.components.teams;

  const handleSubmit = async () => {
    const joinedTeam = await handleJoinTeam();
    if (joinedTeam) {
      onClose(joinedTeam);
    }

    return joinedTeam;
  };

  const handleLeaveTeamConfirmation = useCallback(
    async (leave: boolean) => {
      if (!leave) {
        closeLeaveTeam();
        return;
      }

      try {
        startDeleting();
        const leftTeam = await handleLeaveTeam(currentTeam!.id);
        if (!leftTeam) {
          return;
        }

        onClose();
        return;
      } finally {
        stopDeleting();
        closeLeaveTeam();
      }
    },
    [
      currentTeam,
      onClose,
      handleLeaveTeam,
      startDeleting,
      stopDeleting,
      closeLeaveTeam,
    ],
  );

  return (
    <PromptWrapper
      isVisible={isVisible}
      title={title}
      onClose={() => onClose()}>
      <Content>
        <TeamContent>
          <TeamSelect>
            <TeamSelection
              currentTeam={currentTeam}
              teams={teams}
              onTeamChange={handleTeamChange}
              onJoinTeam={handleSubmit}
            />
          </TeamSelect>
          <Image src={teamsConfig?.banner || teamImg} alt={''} />
        </TeamContent>
        <Footer>
          <ButtonContainer>
            <Button isLoading={isSubmitting} onClick={handleSubmit}>
              {translate('sdk.web.onboarding.teams.confirm')}
            </Button>
          </ButtonContainer>
          {currentTeam && <CtaLink onClick={openLeaveTeam}>Leave team</CtaLink>}
          <Link onClick={() => onClose()}>
            {translate('sdk.web.onboarding.skip.link')}
          </Link>
        </Footer>
        <ErrorBlock />
      </Content>
      {isLeaveTeamOpen && (
        <DialogBox
          promptMessage="Are you sure?"
          onConfirmation={handleLeaveTeamConfirmation}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isDeleting}
        />
      )}
    </PromptWrapper>
  );
};

export default TeamSettingsPrompt;
