import React, {useContext} from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {formatLotteryDate} from 'utils/date';
import {addGlobalBoxShadow} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import ImageCard from '../../ImageCard/ImageCard';
import {SettingsContext} from 'contexts/SettingsContext';

const Card = styled.div`
  width: 100%;

  ${addGlobalBoxShadow()}
`;

const Text = styled(CopyTextSm)<{sm?: boolean}>`
  display: block;
  margin-bottom: ${(props) => (props.sm ? '0' : '3px')};
  padding: 0 5px;
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
`;

const bgColors: any = {
  ongoing: '#fff',
  won: '#ECDA0D',
  lost: '#CCCCCC',
};

const LotCard = (props: any) => {
  const {lot} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const formattedDate = formatLotteryDate({date: lot.lottery.end_date});

  return (
    <Card>
      <ImageCard
        // @ts-ignore
        bgColor={
          organizationConfig.theme.components.lots?.cardBgColors?.[
            lot.status
          ] || bgColors[lot.status]
        }
        img={lot.lottery.image}>
        <Text>{lot.title || lot.lottery.title}</Text>
        <Text sm>
          <CopyTextSm>
            {translate('sdk.web.lots.number', {
              key: '{value}',
              value: lot.lots.length,
            })}
          </CopyTextSm>
        </Text>
        <Text sm>
          <CopyTextSm>
            {translate('sdk.web.lots.draw.date', {
              key: '{date}',
              value: formattedDate,
            })}
          </CopyTextSm>
        </Text>
      </ImageCard>
    </Card>
  );
};

export default LotCard;
