import {fetchAPiClient} from './api';
import {CustomActivity} from 'types/CustomActivity';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});
const activityApiClient = fetchAPiClient({subdomain: 'activity'});

export const getCustomActivityCategories = async () => {
  const url = '/custom-activity-category';
  const {data} = await defaultApiClient.get<any>(url, {
    params: {limit: 500, activities_limit: 1, activities: true},
  });

  return data
    .map((customCategory: any) => {
      if (!customCategory.activities?.length) {
        return null;
      }

      if (!customCategory.enabled) {
        return null;
      }

      const activity = customCategory.activities.sort(
        (a: any, b: any) => a.order - b.order,
      )[0];
      return {
        ...activity,
        categoryName: customCategory.name,
        categoryId: customCategory.id,
        categoryImage: customCategory.mobile_banner,
      };
    })
    .filter((activity: any) => activity);
};

interface GetCustomActivities {
  category: number;
}

export const getCustomActivities = async ({
  category,
}: GetCustomActivities): Promise<CustomActivity> => {
  const url = `custom-activity-category/${category}/custom-activities`;
  const {data} = await defaultApiClient.get<any>(url, {params: {limit: 500}});
  return data.sort((a: any, b: any) => a.order - b.order);
};

interface CompleteActivity {
  id?: number;
  token?: string;
}

type CompleteActivityResponse = {
  data: Array<{
    activity_id: number;
    id: number;
  }>;
};

export const completeActivity = async ({id, token}: CompleteActivity) => {
  const client = activityApiClient;
  const data = {
    data: {
      custom_activity: true,
      list: [
        {
          source_id: 1,
          amount: 1,
          activity_id: token ? undefined : id,
          activity_token: token,
          start_time: new Date(),
          end_time: new Date(),
        },
      ],
    },
  };
  const url = 'activity/create';
  return client.post<any, CompleteActivityResponse>(url, data);
};

export const getCustomActivity = async ({
  id,
}: {
  id: number;
}): Promise<CustomActivity> => {
  const client = defaultApiClient;
  const url = `custom-activity/${id}`;
  const response = await client.get<CustomActivity>(url);
  return response.data;
};
