import React, {useContext, useMemo, useRef, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';

import routes from 'config/routes';
import {ACCEPT_PP_URL_PARAM, LOGIN_ONLY_URL_PARAM} from 'constants/auth';
import {NATIVE_MESSAGES} from 'constants/native-events';
import {AuthContext} from 'contexts/AuthContext';
import {SettingsContext} from 'contexts/SettingsContext';
import useAuthCheck from 'hooks/auth/use-auth-check';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useToast} from 'hooks/use-toast';
import {useForm} from 'hooks/forms/use-form';
import {useGetNext} from 'hooks/utils/use-links';
import {login, LoginData} from 'services/account';
import {sendMessageToNativeApp} from 'services/native-api';

import Input from 'components/Form/Input';
import InfoPage from 'components/InfoPage/InfoPage';
import CtaLink from 'components/CtaLink/CtaLink';
import AccountTitle from 'components/Account/AccountTitle';
import AccountSubtitle from 'components/Account/AccountSubtitle';
import FormRow from 'components/Form/FormRow';
import FormButton from 'components/Form/FormButton';
import PasswordResetPrompt from 'components/Account/PasswordResetPrompt';
import AccountFooter from 'components/Account/AccountFooter';
import AccountButtonWrapper from 'components/Account/AccountButtonWrapper';
import AccountForm from 'components/Account/AccountForm';

const InfoRow = styled.div`
  margin-top: 20px;
`;

const initialValues = {
  email: '',
  password: '',
};

const LoginPage = () => {
  useAuthCheck();
  const formRef = useRef<HTMLFormElement>(null);
  const {updateAuthToken} = useContext(AuthContext);
  const {setIsPrivacyPolicyAccepted} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const {reloadUserProfile} = useUserProfile();
  const history = useHistory();
  const {search} = useLocation();
  const toast = useToast();
  const {getKeyDownListener} = useForm();
  const {next} = useGetNext();
  const {push, goBack, replace} = history;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForgotPasswordPromptVisible, setIsForgotPasswordPromptVisible] =
    useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('sdk.web.validations.email.required'))
      .email(translate('sdk.web.contact.form.email.error.invalid')),
    password: yup
      .string()
      .required(translate('sdk.web.validations.password.required')),
  });

  const {loginOnly, acceptPP} = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const loginOnly = urlParams.get(LOGIN_ONLY_URL_PARAM) === 'true';
    const acceptPP = urlParams.get(ACCEPT_PP_URL_PARAM) === 'true';

    return {loginOnly, acceptPP};
  }, [search]);

  const onClose = () => {
    if (history.length > 2) {
      goBack();
    } else {
      push(routes.SETTINGS.href);
    }
  };

  const onSubmit = async (data: LoginData) => {
    setIsSubmitting(true);

    try {
      const {
        data: {token, uuid},
      } = await login({data});

      updateAuthToken({token});
      sendMessageToNativeApp({
        message: {
          type: NATIVE_MESSAGES.UPDATE_TOKEN,
          uuid,
          new_token: token,
        },
      });
      await reloadUserProfile();
      formik.resetForm();
      toast.success(translate('sdk.web.account.login.success'));
      setIsSubmitting(false);

      if (acceptPP) {
        sendMessageToNativeApp({message: {type: 'pp_accepted'}});
        setIsPrivacyPolicyAccepted(true);
      }

      if (next) {
        replace(next);
      } else {
        push(routes.SETTINGS.ACCOUNT.href);
      }
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = translate(
        errorKey || 'sdk.web.account.login.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const toggleForgotPasswordPromptClose = () => {
    setIsForgotPasswordPromptVisible(!isForgotPasswordPromptVisible);
  };

  return (
    <React.Fragment>
      <InfoPage
        title={translate('sdk.web.account.profile.title')}
        onClose={onClose}>
        <AccountForm onSubmit={formik.handleSubmit} ref={formRef}>
          <div>
            <AccountTitle>
              {translate('sdk.web.account.login.title')}
            </AccountTitle>
            <AccountSubtitle>
              {translate('sdk.web.account.login.message')}
            </AccountSubtitle>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.email.label')}
                name={'email'}
                type={'email'}
                formik={formik}
                value={formik.values.email}
                onChange={formik.handleChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'next'}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.password.label')}
                name={'password'}
                type={'password'}
                formik={formik}
                value={formik.values.password}
                onChange={formik.handleChange}
                enterkeyhint={'go'}
              />
            </FormRow>
            {!loginOnly && (
              <InfoRow>
                <CtaLink onClick={toggleForgotPasswordPromptClose}>
                  {translate('sdk.web.account.password.reset.cta')}
                </CtaLink>
              </InfoRow>
            )}
          </div>
          <AccountFooter>
            <AccountButtonWrapper>
              <FormButton isLoading={isSubmitting}>
                {translate('sdk.web.account.login.button')}
              </FormButton>
            </AccountButtonWrapper>
            {!loginOnly && (
              <CtaLink as={Link} to={routes.SETTINGS.REGISTER.href} noMargin>
                {translate('sdk.web.account.register.cta')}
              </CtaLink>
            )}
          </AccountFooter>
        </AccountForm>
      </InfoPage>
      <PasswordResetPrompt
        isVisible={isForgotPasswordPromptVisible}
        onClose={toggleForgotPasswordPromptClose}
      />
    </React.Fragment>
  );
};

export default LoginPage;
