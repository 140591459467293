import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {useBoolean} from 'hooks/utils/use-boolean';
import {OfferType} from 'types/Offers';
import {addGlobalCardBorder, addGlobalSquareCorners} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Button from 'components/Button/Button';
import Card from 'components/Rewards/Deals/Card';
import OnsiteCTA from '../OnsiteCTA';
import CtaLink from 'components/CtaLink/CtaLink';
import OnsiteHowTo from 'components/Rewards/Deals/OnsiteCTA/OnsiteHowTo';

const Wrapper = styled.div`
  padding: 0;
  padding-top: 10px;
  width: 100%;
  min-height: 100%;
`;

const DealCard = styled.div`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const PageLink = styled(CtaLink)<{mb?: string}>`
  text-align: center;
  margin-bottom: ${(props) => props.mb || '20px'};
`;

const Cta = styled.div`
  padding-top: 5px;
`;

const Content = styled(CopyTextSm)`
  display: block;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) =>
    props.theme.components.offers?.hasSquareCorners || '7px'};
  padding: 10px;
  word-break: break-word;

  img {
    max-width: 100%;
  }

  ${addGlobalSquareCorners()}
  ${addGlobalCardBorder()}
`;

const OfferDetails = (props: any) => {
  const {
    deal,
    isSubmitting,
    isRecoinEnough,
    userRecoins,
    termsLink,
    onOfferPurchase,
    isLottery,
    isScanned,
    children,
    updateVoucherWishlist,
  } = props;

  const {translate} = useTranslations();
  const {organizationConfig} = useOrganization();
  const [isOnsiteHowToVbl, showOnsiteHowTo, hideOnsiteHowTo] = useBoolean();

  const variant = isRecoinEnough ? 'primary' : 'gray';
  const offerKey = isLottery
    ? 'sdk.web.offers.get.lot'
    : isScanned
    ? 'sdk.web.offers.get.voucher.cp'
    : 'sdk.web.offers.get.voucher';
  const ctaText = translate(offerKey) || translate('sdk.web.offers.get');

  const isOnsiteOffer = useMemo(
    () => deal.offer_type_id === OfferType.OFFLINE,
    [deal],
  );

  const isDetailsCardTransparent =
    organizationConfig.theme.components.offers?.isDetailsCardTransparent;

  return (
    <Wrapper>
      <DealCard>
        <Card
          {...deal}
          userRecoins={userRecoins}
          recoins={deal.recoins}
          isTransparent={isDetailsCardTransparent}
          updateVoucherWishlist={updateVoucherWishlist}>
          <Cta>
            {isOnsiteOffer && (
              <OnsiteCTA
                voucher={deal}
                isScanned={isScanned}
                onScan={onOfferPurchase}
              />
            )}
            <ButtonContainer>
              {(!isOnsiteOffer || isScanned) && (
                <Button
                  isLoading={isSubmitting}
                  variant={variant}
                  onClick={onOfferPurchase}>
                  {ctaText}
                </Button>
              )}
            </ButtonContainer>
            {isOnsiteOffer && (
              <>
                <PageLink onClick={showOnsiteHowTo}>
                  {translate('sdk.web.offers.how.to.cta')}
                </PageLink>
                <PageLink as={Link} to={routes.REWARDS.NEARBY_OFFERS.href}>
                  {translate('sdk.web.offers.nearby')}
                </PageLink>
              </>
            )}
            <PageLink as={Link} to={termsLink} mb="10px">
              {translate('sdk.web.offers.terms')}
            </PageLink>
          </Cta>
        </Card>
      </DealCard>
      <Content>{children}</Content>
      <OnsiteHowTo
        deal={deal}
        isVisible={isOnsiteHowToVbl}
        onClose={hideOnsiteHowTo}
      />
    </Wrapper>
  );
};

export default OfferDetails;
