import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';

import appConfig from 'config/app';
import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import useNavigation from 'hooks/app/use-navigation';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {STEPS_KEY} from './types';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import SummaryPage from 'components/SummaryPage/SummaryPage';

import banner from './images/banner.svg';

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  min-height: 100vh;
  overflow-y: scroll;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;

  a {
    color: ${(props) =>
      props.theme.components.links?.textColor || props.theme.colors.black};
  }
`;

interface PageProps {
  title: string;
  btn: {
    text: string;
    type?: 'submit' | 'button';
    onClick?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
  };
  currentStep?: STEPS_KEY;
  children: React.ReactNode;
}

const Page = (props: PageProps) => {
  const {title, btn, currentStep, children} = props;
  const {updateBg} = useBodyBg({type: 'light'});
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});

  useEffect(
    () => {
      return () => updateBg({type: 'primary'});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateBg],
  );

  const carTypeSupport = useMemo(() => {
    const subject = translate('sdk.web.eCar.form.missing.type.email.subject');
    const emailLink = `<b><a href="mailto:${appConfig.supportEmail}?subject=${subject}">${appConfig.supportEmail}</a></b>`;

    return translate('sdk.web.eCar.form.missing.type.support', {
      key: '{email}',
      value: emailLink,
    });
  }, [translate]);

  return (
    <Modal>
      <Wrapper>
        <SummaryPage title={title} onClose={handleGoBack}>
          <Content>
            <div>
              <img className="mb-base" src={banner} alt="" />
              {children}
            </div>
            <div>
              {currentStep === STEPS_KEY.CAR_TYPE && (
                <CopyText
                  as="div"
                  className="mb-base"
                  dangerouslySetInnerHTML={{__html: carTypeSupport}}
                />
              )}
              <Button
                className="mb-base"
                type={btn.type}
                isLoading={btn.isLoading}
                disabled={btn.disabled}
                onClick={btn.onClick}>
                {btn.text}
              </Button>
              <CopyTextSm>{translate('sdk.web.eCar.hint.later')}</CopyTextSm>
            </div>
          </Content>
        </SummaryPage>
      </Wrapper>
    </Modal>
  );
};

export default Page;
