import React, {useContext} from 'react';
import styled, {withTheme} from 'styled-components';
import {NavLink} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {addGlobalCardBorder, addGlobalSquareCorners} from 'utils/theme';

import LinkText from 'components/Text/LinkText/LinkText';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  padding: 3px;

  ${addGlobalSquareCorners()}
  ${addGlobalCardBorder()}
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
`;

const Tab = styled(NavLink)`
  flex: 1;
  text-align: center;
  border-radius: 7px;
  padding: 5px 1px;
  cursor: pointer;
  color: inherit;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    background-color: #f5f5f5;
    color: inherit;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  &.active {
    .link-text {
      font-weight: ${(props) =>
        props.theme.components.offers.activeTabFontWeight};
    }
  }

  ${addGlobalSquareCorners()}
`;

const tabs = [
  {
    translationKey: 'sdk.web.offers.tab.offers',
    key: 'deals',
    isActive: true,
    to: routes.REWARDS.DEALS.href,
  },
  {
    translationKey: 'sdk.web.offers.tab.vouchers',
    key: 'vouchers',
    to: routes.REWARDS.VOUCHERS.href,
  },
  {
    translationKey: 'sdk.web.offers.tab.lots',
    key: 'lots',
    to: routes.REWARDS.LOTS.href,
  },
];

const Tabs = (props: any) => {
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const allTabs = tabs.filter(
    ({key}) => key !== 'lots' || !organizationConfig.features.hideLotsTab,
  );

  return (
    <Wrapper>
      <List>
        {allTabs.map(({translationKey, key, to, isActive}) => (
          <Tab
            key={key}
            to={to}
            // @ts-ignore
            {...isActive}
            activeStyle={{
              backgroundColor: props.theme.components.offers.activeTabBgColor,
              color:
                props.theme.components.offers.activeTabColor ||
                props.theme.colors.white,
            }}>
            <LinkText className="link-text">
              {translate(translationKey)}
            </LinkText>
          </Tab>
        ))}
      </List>
    </Wrapper>
  );
};

export default withTheme(Tabs);
