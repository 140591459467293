import React, {useContext, useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {ErrorBoundary} from 'react-error-boundary';

import {useUserLoader} from 'hooks/use-user-loader';
import styled, {ThemeContext} from 'styled-components';
import {useUserProfile} from 'hooks/use-user-profile';
import {sendMessageToNativeApp} from 'services/native-api';
import {captureException} from 'services/errors';
import GlobalStyle from 'theme/globalStyles';

import RootPage from 'pages';
import ThemeWrapper from 'components/ThemeWrapper/ThemeWrapper';
import NativeWrapper from 'components/NativeWrapper/NativeWrapper';
import Loader from 'components/Loader/Loader';
import ErrorBoundaryFallback from 'components/Errors/ErrorBoundaryFallback';
import './App.css';
import BaseError from 'components/Errors/BaseError';

const PageLoader = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  justify-content: center;
`;

/** App component */
const App = () => {
  const {isLoadingUserProfile, errorLoadingUserProfile, loadUserProfile} =
    useUserLoader();
  const currentTheme = useContext(ThemeContext);
  const {userProfile} = useUserProfile();

  useEffect(() => {
    console.log('Setting init timeout v3');

    const message = {type: 'init_request'};
    setTimeout(() => {
      console.log('Calling init request v3');

      sendMessageToNativeApp({message});
    }, 500);
  }, []);

  if (!currentTheme.font || !userProfile?.user) {
    console.log('Showing loader - user not initialized');

    return (
      <NativeWrapper>
        <PageLoader>
          <Loader color={'#222'} />
        </PageLoader>
      </NativeWrapper>
    );
  }

  if (errorLoadingUserProfile && !userProfile?.user) {
    return (
      <NativeWrapper>
        <BaseError
          onRetry={loadUserProfile}
          isRetrying={isLoadingUserProfile}
        />
      </NativeWrapper>
    );
  }

  return <RootPage userProfile={userProfile} />;
};

const logError = (error: Error) => {
  captureException({
    error,
    errorInfo: 'ErrorBoundary Exception',
  });
};

const AppWrapper = () => {
  return (
    <ThemeWrapper>
      <GlobalStyle />
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={logError}>
        <App />
      </ErrorBoundary>
      <ToastContainer
        position="top-center"
        theme="colored"
        hideProgressBar={true}
        icon={false}
        style={{
          zIndex: 9999999999,
        }}
      />
    </ThemeWrapper>
  );
};
export default AppWrapper;
