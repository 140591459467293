import React from 'react';

import {TeamLeaderBoard} from 'services/teams';
import {LeaderBoard, TeamType} from 'types/Team';

import GroupLeaderBoard from './GroupLeaderBoard';

interface AllGroupLeaderboardsProps {
  groups: LeaderBoard[];
  isLoading: boolean;
  teamLeaderboard?: TeamLeaderBoard;
  teamTypes?: Array<TeamType>;
}

const AllGroupLeaderboards = (props: AllGroupLeaderboardsProps) => {
  const {groups, isLoading, ...rest} = props;

  return (
    <>
      {groups.map((group: LeaderBoard) => (
        <GroupLeaderBoard
          key={group.name}
          group={group}
          isLoading={isLoading}
          {...rest}
        />
      ))}
    </>
  );
};

export default AllGroupLeaderboards;
