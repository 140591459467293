import React, {useContext} from 'react';
import pick from 'lodash/pick';

import {AuthContext} from 'contexts/AuthContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {ActivityContext} from 'contexts/ActivityContext';
import {useNativeApp} from 'store/effects';
import {logData} from 'services/log';

const NativeWrapper = (props: any) => {
  const {addAuth} = useContext(AuthContext);
  const {
    appId,
    setIsAppInitialized,
    setDidAppInitFail,
    setInitFailedMessage,
    setLastTransmitted,
    setIsGPSEnabled,
    setIsMotionFitnessEnabled,
    setIsMotionTrackingEnabled,
    setIsPrivacyPolicyAccepted,
    setIsNotificationEnabled,
    setIsBatteryOptimizationEnabled,
    setAppPermissions,
    setAppName,
    setAppId,
    setAppVersion,
    setSdkVersion,
    setAppFeatures,
    setInitialPage,
    setDebugInfo,
  } = useContext(SettingsContext);
  const {isDiagnosticsEnabled, updateActivityData} =
    useContext(ActivityContext);

  const onNativeMessage = (e: any) => {
    console.log(e);

    const {detail} = e;

    switch (detail.type) {
      case 'initialize':
        setIsAppInitialized(true);

        const authData = pick(detail, ['token', 'headers', 'env']);
        addAuth && addAuth(authData);

        if (!appId) {
          setAppName(detail.app_name);
          setAppVersion(detail.app_version || '1.0.0');
          setSdkVersion(detail.sdk_version);
          setAppId(authData.headers['x-app']);
          setIsPrivacyPolicyAccepted(detail.pp_accepted);
        }

        const lastTransmitted = detail.motiontag.last_transmitted;
        setLastTransmitted(lastTransmitted);
        updateActivityData && updateActivityData({data: detail.data});

        setAppFeatures(detail.features || []);
        setInitialPage(detail.initialPage);

        if (detail.permission) {
          const appPermissions = Object.keys(detail.permission[0]).filter(
            (key) => detail.permission[0][key],
          );
          setAppPermissions(appPermissions);
        }
        break;
      case 'init_failed':
        setDidAppInitFail(true);
        setInitFailedMessage(detail.value);
        break;
      case 'source_enabled':
        setIsMotionTrackingEnabled(true);
        break;
      case 'source_disabled':
        setIsMotionTrackingEnabled(false);
        break;
      case 'pp_accepted':
        setIsPrivacyPolicyAccepted(detail.accepted);
        break;
      case 'permissions_status':
        const isGPSEnabled = detail.permission.find(
          (permission: any) => permission.gps,
        );
        const isMotionFitnessEnabled = detail.permission.find(
          (permission: any) => permission.motion,
        );
        const isNotificationEnabled = detail.permission.find(
          (permission: any) => permission.notification,
        );
        const isBatteryOptimizationEnabled = detail.permission.find(
          (permission: any) => permission['battery-optimization'],
        );

        setIsGPSEnabled(!!isGPSEnabled);
        setIsMotionFitnessEnabled(!!isMotionFitnessEnabled);
        setIsNotificationEnabled(!!isNotificationEnabled);
        setIsBatteryOptimizationEnabled(!!isBatteryOptimizationEnabled);
        break;
      case 'tracking_info':
        setLastTransmitted(detail.motiontag.last_transmitted);
        break;
      case 'debug_info':
        setDebugInfo(detail.data);
        break;
      case 'save_data':
        updateActivityData && updateActivityData({data: detail.data});
        break;
    }

    if (isDiagnosticsEnabled) {
      logData({
        message: 'Data sent from native',
        context: detail,
      });
    }
  };

  useNativeApp(onNativeMessage);

  return <>{props.children}</>;
};

export default NativeWrapper;
