/* eslint-disable */
/* eslint-disable react/prop-types */

import React, {useContext, useEffect} from 'react';

import {OpenWidgetContext} from 'contexts/OpenWidgetContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {ActivityContext} from 'contexts/ActivityContext';
import config from 'config/openWidgets';

import Widget from 'components/OpenWidgets/Widget';

interface OpenWidgetSlotProps {
  position?: string;
  load?: boolean;
}

const OpenWidgetSlot = ({
  position = 'default',
  load = false,
}: OpenWidgetSlotProps) => {
  const openWidgetContext = useContext(OpenWidgetContext);
  const settingsContext = useContext(SettingsContext);
  const {isDiagnosticsEnabled} = useContext(ActivityContext);

  let widgets: any[] = [];

  openWidgetContext.widgetRegistry.forEach((element) => {
    if (element.position != position) return;

    // @ts-ignore
    widgets.push(
      // @ts-ignore
      <Widget element={element} />,
    );
  });

  useEffect(() => {
    if (!load) return;

    if (!settingsContext?.appId) return;

    let loader = new OpenWidgetLoader(
      config,
      openWidgetContext,
      settingsContext,
    );
    loader.load();
  }, [settingsContext?.appId]);

  const printedLogs = openWidgetContext.logs.map((log) => <li>{log}</li>);

  return (
    <React.Fragment>
      {isDiagnosticsEnabled && false && (
        <div>
          <h5>Position - {position}</h5>
          <h5>Env - {process.env.REACT_APP_ENV}</h5>
          <h5>App - {settingsContext.appId}</h5>
          <ul>{printedLogs}</ul>
        </div>
      )}

      {widgets}
    </React.Fragment>
  );
};

export type UserModel = {
  id: number;
  coins: number;
  account_type: number;
  email: string;
  firstname: string;
  lastname: string;
};

export class OpenWidgetLoader {
  private config: any;
  private openWidgetContext: any;
  private settingsContext: any;

  constructor(config: any, openWidgetContext: any, settingsContext: any) {
    this.config = config;
    this.openWidgetContext = openWidgetContext;
    this.settingsContext = settingsContext;
  }

  public load() {
    console.log('Loading inbuilt widgets', config);

    for (let item of config.widgets) {
      this.process(item);
    }
  }

  public process(item: any) {
    console.log('Loading widget', item);

    if (!item.enviroments.includes(process.env.REACT_APP_ENV || 'production')) {
      console.error('Error - ENV not set', item.enviroments);
      return;
    }

    if (!item.apps.includes(this.settingsContext.appId)) {
      console.error('Error - App not set', item.apps);
      return;
    }

    // @ts-ignore
    window.registerWidget(item.elementName, item.scriptUrl, item.position, item);
  }
}

export default OpenWidgetSlot;
