import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';

import {SettingsContext} from 'contexts/SettingsContext';
import useCoinsTransferLinkShare from 'hooks/wallet/use-coins-transfer-link-share';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {TalerTransaction, TRANSACTION_STATUS} from 'types/Taler';
import {formatDate} from 'utils/date';
import {formatNumber} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Button from 'components/Button/Button';
import successImage from 'pages/Dashboard/WalletsPage/images/success.svg';
import Loader from 'components/Loader/Loader';

import {ReactComponent as RepeatIcon} from 'icons/repeat.svg';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;

  img {
    max-width: 100%;
  }
`;

const Text = styled(CopyText)`
  margin-bottom: 10px;
`;

const Share = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

interface TransactionModalProps {
  transaction: TalerTransaction;
  onClose: () => any;
}

const TransactionModal = (props: TransactionModalProps) => {
  const {transaction, onClose} = props;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {shareCoinsTransferLink} = useCoinsTransferLinkShare({
    shareText: 'sdk.web.gnu.taler.trf.share.text',
  });
  const {getShortTokenText} = useTokenTranslation();

  const fields = useMemo(() => {
    const amount = parseFloat(transaction.amount);

    return [
      {
        key: 'date',
        label: 'Date',
        value: formatDate({date: transaction.created_at}),
      },
      {
        key: 'type',
        label: 'Type',
        value:
          translate(`sdk.web.gnu.taler.type.${transaction.type}`) ||
          transaction.type,
      },
      {
        key: 'status',
        label: 'Status',
        value:
          translate(`sdk.web.gnu.taler.status.${transaction.status}`) ||
          transaction.status,
      },
      {
        key: 'coins',
        label: getShortTokenText(2),
        value: formatNumber({
          number: Math.abs(amount),
          skipNormalization: true,
          dp: 2,
        }),
      },
    ];
  }, [transaction, translate, getShortTokenText]);

  return (
    <PromptWrapper
      isVisible={true}
      title={translate('sdk.web.gnu.taler.trx.details')}
      onClose={onClose}>
      <Content>
        <div>
          <div className="mb-base">
            {transaction.status === TRANSACTION_STATUS.PENDING &&
              (transaction.qr_code_url ? (
                <>
                  <img src={transaction.qr_code_url} alt="QR Code" />
                  <CopyText as="div">
                    {translate('sdk.web.gnu.taler.trx.details.qr')}
                  </CopyText>
                </>
              ) : (
                <Loader color="#222" sm />
              ))}
            {transaction.status === TRANSACTION_STATUS.DONE && (
              <img
                src={
                  organizationConfig.theme.components.transfer?.successImage ||
                  successImage
                }
                alt="Success"
                className="mb-base"
              />
            )}
          </div>
          <div className="mb-2">
            {fields.map(({key, label, value}) => (
              <Text key={key} as="div">
                <b>{label}: </b>
                {value}
              </Text>
            ))}
          </div>
          {transaction.status === TRANSACTION_STATUS.PENDING &&
          transaction.taler_uri ? (
            <Share
              onClick={(e) => {
                e.stopPropagation();
                shareCoinsTransferLink(transaction.share_uri);
              }}>
              <RepeatIcon />
              <CopyText>
                <b> {translate('sdk.web.gnu.taler.trx.details.share')}</b>
              </CopyText>
            </Share>
          ) : (
            <div />
          )}
        </div>
        <Button onClick={onClose}>{translate('sdk.web.dialog.box.ok')}</Button>
      </Content>
    </PromptWrapper>
  );
};

export default TransactionModal;
