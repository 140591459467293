import React from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

import BaseError, {BaseErrorProps} from 'components/Errors/BaseError';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 400000;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
`;

const LogoutError = (props: BaseErrorProps) =>
  createPortal(
    <Wrapper>
      <BaseError {...props} />
    </Wrapper>,
    document.body,
  );

export default LogoutError;
