export const NATIVE_EVENTS = {
  CAMERA_IMAGE: 'camera_image',
  NEW_TOKEN: 'submit_new_token',
  CURRENT_LOCATION: 'current_location',
  QRCODE_URL: 'qrcode_url',
  GLOBAL_QRCODE_URL: 'global_qrcode_url',
  QRCODE_CLOSE: 'qr_code_scanner_did_close',
};

export const NATIVE_MESSAGES = {
  CAMERA_IMAGE: 'request_camera_image',
  CLOSE_APP: 'close',
  ONBOARDING_COMPLETED: 'onboarding_completed',
  REQUEST_CURRENT_LOCATION: 'request_current_location',
  REQUEST_DEBUG_INFO: 'request_debug_info',
  REQUEST_GIVE_DIRECTION: 'request_give_direction',
  REQUEST_QRCODE_SCAN: 'request_qrcode_scanner',
  REQUEST_OPEN_MAIL: 'request_open_email_app',
  REQUEST_NEW_TOKEN: 'request_new_token',
  REQUEST_FRESH_TOKEN: 'new_token',
  SHARE_BADGE: 'share_badge',
  UPDATE_NOTIFICATION: 'notification',
  UPDATE_TOKEN: 'update_token',
};
