export const LIMITS_ERROR_CODES = {
  SOFT_LIMIT: 3405,
  HARD_LIMIT: 3406,
  DAILY_LIMIT: 3407,
};

export const METERING_IMAGE_ERROR_CODES = {
  INVALID_IMAGE_UPLOADED: 3408,
  UNABLE_TO_PROCESS_IMAGE: 3409,
  INCORRECT_ORIENTATION: 3410,
};
