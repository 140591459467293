import React from 'react';
import {FallbackProps} from 'react-error-boundary';

import BaseError from 'components/Errors/BaseError';

const ErrorBoundaryFallback = ({resetErrorBoundary}: FallbackProps) => {
  return <BaseError onRetry={resetErrorBoundary} />;
};

export default ErrorBoundaryFallback;
