import axios from 'axios';
import Parser from 'rss-parser';
import {getDeviceLanguage} from 'utils/language';

const parser = new Parser({
  customFields: {
    item: ['headerImage'],
  },
});

const langUrls: Record<string, string> = {
  en: 'https://smile-sintra.com/en.xml',
  pt: 'https://smile-sintra.com/pt.xml',
};

export const getNewsFeed = async () => {
  try {
    const language = getDeviceLanguage();
    const {data} = await axios.get<any>(langUrls[language] || langUrls.en);
    const feed = await parser.parseString(data);

    return feed.items.map((item) => ({
      ...item,
      summary: item.contentSnippet,
      // @ts-ignore
      headerImage: item.enclosure?.url,
    }));
  } catch (error) {
    return [];
  }
};
