import {FC, SVGProps, useMemo} from 'react';
import {useTranslations} from 'hooks/use-translations';
import {getMonthKey, getMonthKeyTranslation} from 'utils/date';
import {PurchasedCompensation} from '../types/Compensation';
import {ReactComponent as Jan} from 'icons/climate-suns/jan.svg';
import {ReactComponent as Feb} from 'icons/climate-suns/feb.svg';
import {ReactComponent as Mar} from 'icons/climate-suns/mar.svg';
import {ReactComponent as Apr} from 'icons/climate-suns/apr.svg';
import {ReactComponent as May} from 'icons/climate-suns/may.svg';
import {ReactComponent as Jun} from 'icons/climate-suns/jun.svg';
import {ReactComponent as Jul} from 'icons/climate-suns/jul.svg';
import {ReactComponent as Aug} from 'icons/climate-suns/aug.svg';
import {ReactComponent as Sep} from 'icons/climate-suns/sep.svg';
import {ReactComponent as Oct} from 'icons/climate-suns/oct.svg';
import {ReactComponent as Nov} from 'icons/climate-suns/nov.svg';
import {ReactComponent as Dec} from 'icons/climate-suns/dec.svg';
import {ReactComponent as EmptySun} from 'icons/climate-suns/empty.svg';

const icons = [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec];

export type ClimateSun = {
  Icon: FC<SVGProps<SVGSVGElement>>;
  monthIndex: number;
  monthText: string;
};

export type ClimateMonth = ClimateSun & {
  compensation?: PurchasedCompensation;
  isActive: boolean;
};

const useClimateSuns = () => {
  const {translate} = useTranslations();
  const climateSuns = useMemo(
    () =>
      icons.map((Icon, index) => ({
        Icon,
        monthIndex: index,
        monthText: getMonthKeyTranslation({
          monthKey: getMonthKey(index),
          translate,
        }).substring(0, 3),
      })),
    [translate],
  );

  return {
    climateSuns,
  };
};

export {EmptySun};

export default useClimateSuns;
