import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {ManualMeasurement, MeteringImageData} from 'services/metering';
import {OnCompleteProps} from './types';
import {ELEMENT_CONSUMPTION_SCREENS, ElementScreenProps} from '../../types';

import Photo from './Photo';
import Settings from './Settings';
import Success from './Success';
import FirstTime from './FirstTime';
import MonthResults from './MonthResults';

const Consumption = (props: ElementScreenProps) => {
  const {element, elementData, stats, onComplete, closeKey} = props;
  const [activeScreen, setActiveScreen] = useState<ELEMENT_CONSUMPTION_SCREENS>(
    ELEMENT_CONSUMPTION_SCREENS.PHOTO,
  );
  const [imageData, setImageData] = useState<MeteringImageData>();
  const [manualMeasurement, setManualMeasurement] =
    useState<ManualMeasurement>();

  const screens = useMemo(
    () => [
      {
        key: ELEMENT_CONSUMPTION_SCREENS.PHOTO,
        Component: Photo,
      },
      {
        key: ELEMENT_CONSUMPTION_SCREENS.SETTINGS,
        Component: Settings,
      },
      {
        key: ELEMENT_CONSUMPTION_SCREENS.SUCCESS,
        Component: Success,
      },
      {
        key: ELEMENT_CONSUMPTION_SCREENS.FIRST_TIME,
        Component: FirstTime,
      },
      {
        key: ELEMENT_CONSUMPTION_SCREENS.MONTH_RESULTS,
        Component: MonthResults,
      },
    ],
    [],
  );

  const handleClose = useCallback(() => {
    switch (activeScreen) {
      case ELEMENT_CONSUMPTION_SCREENS.SETTINGS: {
        setActiveScreen(ELEMENT_CONSUMPTION_SCREENS.PHOTO);
        return;
      }
      default: {
        onComplete();
        return;
      }
    }
  }, [activeScreen, onComplete]);

  useEffect(
    () => {
      if (!closeKey) {
        return;
      }

      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeKey],
  );

  const handleComplete = useCallback(
    (completeParams?: OnCompleteProps) => {
      switch (activeScreen) {
        case ELEMENT_CONSUMPTION_SCREENS.PHOTO: {
          setActiveScreen(ELEMENT_CONSUMPTION_SCREENS.SETTINGS);
          if (completeParams?.data?.imageData) {
            setImageData(completeParams.data.imageData);
          }

          return;
        }
        case ELEMENT_CONSUMPTION_SCREENS.SETTINGS: {
          if (completeParams?.data?.manualMeasurement) {
            setManualMeasurement(completeParams.data.manualMeasurement);
          }
          if (completeParams?.data?.imageData) {
            setImageData(completeParams.data.imageData);
          }

          if (completeParams?.data?.manualMeasurement?.previous_value) {
            setActiveScreen(ELEMENT_CONSUMPTION_SCREENS.SUCCESS);
          } else {
            setActiveScreen(ELEMENT_CONSUMPTION_SCREENS.FIRST_TIME);
          }

          return;
        }
        case ELEMENT_CONSUMPTION_SCREENS.SUCCESS: {
          if (completeParams?.nextScreen) {
            setActiveScreen(completeParams.nextScreen);
          } else if (manualMeasurement?.is_new_month) {
            setActiveScreen(ELEMENT_CONSUMPTION_SCREENS.MONTH_RESULTS);
          } else {
            onComplete();
          }
          return;
        }
        case ELEMENT_CONSUMPTION_SCREENS.MONTH_RESULTS: {
          onComplete(
            completeParams?.nextElementScreen
              ? {
                  nextScreen: completeParams.nextElementScreen,
                }
              : undefined,
          );
          return;
        }
        case ELEMENT_CONSUMPTION_SCREENS.FIRST_TIME: {
          if (completeParams?.nextScreen) {
            setActiveScreen(completeParams.nextScreen);
          } else {
            onComplete();
          }
          return;
        }
        default: {
          onComplete();
          return;
        }
      }
    },
    [activeScreen, manualMeasurement, onComplete],
  );

  return (
    <>
      {screens.map((screen) => (
        <React.Fragment key={screen.key}>
          {activeScreen === screen.key && (
            <screen.Component
              element={element}
              elementData={elementData}
              stats={stats}
              imageData={imageData}
              manualMeasurement={manualMeasurement}
              onClose={handleClose}
              onComplete={handleComplete}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Consumption;
