import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Team, TeamType} from 'types/Team';

import {useTeams} from 'components/ClimateWidgets/Leaderboard/hooks/useTeams';
import TeamSelection from 'components/ClimateWidgets/Leaderboard/TeamSelection/TeamSelection';

const TeamSelect = styled.div`
  display: block;
  margin-bottom: 30px;
`;

interface TeamDropdownProps {
  teamType: TeamType;
}

const TeamDropdown = (props: TeamDropdownProps) => {
  const {teamType} = props;
  const {currentTeam, handleTeamChange, handleJoinTeam, ErrorBlock} = useTeams({
    teams: teamType.teams,
    teamTypeId: teamType.id,
  });

  const handleTeamChangeLocal = useCallback(
    (team: Team) => {
      // Join team automatically on team change if no passphrase is required
      // Otherwise return, passphrase modal has a submit button

      handleTeamChange(team);
      if (team.passphrase_required) {
        return;
      }

      handleJoinTeam(team);
    },
    [handleTeamChange, handleJoinTeam],
  );

  return (
    <div>
      <TeamSelect>
        <TeamSelection
          label={teamType.name}
          teams={teamType.teams}
          currentTeam={currentTeam}
          onTeamChange={handleTeamChangeLocal}
          onJoinTeam={handleJoinTeam}
          usePassphraseModal
        />
      </TeamSelect>
      <ErrorBlock />
    </div>
  );
};

export default TeamDropdown;
