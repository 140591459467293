import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';

import Button from 'components/Button/Button';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyText from 'components/Text/CopyText/CopyText';

import img from 'assets/images/error.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
  padding: 60px 20px 40px;
`;

const Image = styled.img`
  width: 70%;
`;

export interface BaseErrorProps {
  onRetry: () => any;
  isRetrying?: boolean;
}

const BaseError = (props: BaseErrorProps) => {
  const {onRetry, isRetrying} = props;
  const {translate} = useTranslations();

  return (
    <Wrapper>
      <div>
        <HeaderText as="div" className="mb-base">
          {translate('sdk.web.error.fallback.title')}
        </HeaderText>
        <CopyText as="div">
          {translate('sdk.web.error.fallback.subtitle')}
        </CopyText>
      </div>
      <Image src={img} alt="" />
      <Button isLoading={isRetrying} onClick={() => onRetry()}>
        {translate('sdk.web.error.fallback.cta')}
      </Button>
    </Wrapper>
  );
};

export default BaseError;
