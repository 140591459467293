import React, {useCallback} from 'react';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {TeamLeaderBoard} from 'services/teams';
import {TeamType} from 'types/Team';

import LeaderboardLink from 'components/ClimateWidgets/Leaderboard/components/LeaderboardLink';
import TeamSettingsPrompt from 'components/ClimateWidgets/Leaderboard/components/TeamSettings/TeamSettingsPrompt';

interface TeamSettingsProps {
  leaderboard: TeamLeaderBoard;
  teamTypes: Array<TeamType>;
  onClose: (joinedTeam?: boolean) => void;
}

const TeamSettings = (props: TeamSettingsProps) => {
  const {leaderboard, teamTypes, onClose} = props;

  const {translate} = useTranslations();
  const [isTeamPromptOpen, openTeamPrompt, closeTeamPrompt] = useBoolean(false);

  const handlePromptClose = useCallback(() => {
    closeTeamPrompt();
    onClose();
  }, [onClose, closeTeamPrompt]);

  return (
    <>
      <LeaderboardLink onClick={openTeamPrompt}>
        {translate('sdk.web.teams.settings')}
      </LeaderboardLink>
      <TeamSettingsPrompt
        teamTypeId={leaderboard.teamTypeId}
        teamTypes={teamTypes}
        isVisible={isTeamPromptOpen}
        onClose={handlePromptClose}
      />
    </>
  );
};

export default TeamSettings;
