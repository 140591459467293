import Pusher from 'pusher-js';

import config from 'config/pusher';
import {getAuthConfig} from './auth';
import {fetchAPiClient} from './api';
import {getMyProfile} from './user';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

let pusher: Pusher;

export const initNotificationsAuth = async () => {
  const {env, baseDomain, headers} = getAuthConfig();
  const key = config.keys[env];

  const user = await getMyProfile();
  const authEndpoint = `https://sst.${baseDomain}/api/pusher/${user.data.user.id}`;

  pusher = new Pusher(key, {
    cluster: 'eu',
    authEndpoint,
    auth: {
      headers,
    },
  });
};

export const subscribeToNotifications = (name: string) => {
  return pusher.subscribe(name);
};

export const getNotifications = async ({page}: {page: number}) => {
  const client = defaultApiClient;
  const url = '/notification';
  const {
    data: {data},
  } = await client.get<any>(url, {
    params: {
      page,
    },
  });
  return data;
};

export const getNotificationPreferences = async () => {
  const client = defaultApiClient;
  const url = '/notification-preference';
  const {data} = await client.get<any>(url);
  return data?.preferences?.push;
};

type NotificationPreferenceData = {
  type: string;
  enabled: boolean;
};
export const updateNotificationPreference = (
  data: NotificationPreferenceData,
) => {
  const client = defaultApiClient;
  const url = `/notification-preference/push`;
  return client.put(url, data);
};

export const getNotificationStats = async () => {
  const client = defaultApiClient;
  const url = '/notification/stats';
  const {data} = await client.get<any>(url);
  return data;
};

export const markNotificationsAsSeen = async () => {
  const client = defaultApiClient;
  const url = '/notification/clear-unseen';
  return client.post<any>(url);
};
