import {fetchAPiClient} from 'services/api';
import {dataUrlToFile} from 'utils/images';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

type ImageParams = {
  category: string;
  type: string;
  url?: string;
  force?: boolean;
};

interface UploadImageFileParams extends ImageParams {
  image: File;
}

export const uploadImageFile = async <T>(
  data: UploadImageFileParams,
): Promise<T> => {
  const url = data.url || '/image';

  const formData = new FormData();
  formData.append('image', data.image);
  formData.append('category', data.category);
  formData.append('type', data.type);

  if (data.force) {
    formData.append('force', 'true');
  }

  return defaultApiClient.post<FormData, Promise<T>>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface UploadImageDataParams extends ImageParams {
  image: string;
}

export const uploadImageData = async <T>(
  data: UploadImageDataParams,
): Promise<T> => {
  const fileData = {
    category: data.category,
    type: data.type,
    force: data.force,
    image: await dataUrlToFile({dataUrl: data.image, fileName: data.category}),
  };

  return uploadImageFile({...fileData, url: data.url});
};
