import React, {useCallback, useContext} from 'react';
import styled from 'styled-components';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {LeaderBoard, Team, TeamType} from 'types/Team';
import {TeamLeaderBoard} from 'services/teams';
import {getMonthTranslation} from 'utils/date';

import LeaderboardWidget from './LeaderboardWidget';
import PreviousLeaderboardPrompt from './PreviousLeaderboardPrompt';
import LeaderboardLink from './components/LeaderboardLink';
import TeamSettings from './components/TeamSettings';

import bannerImg from './images/competition-banner.svg';

const Cta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type GroupLeaderBoardProps = {
  isLoading: boolean;
  group: LeaderBoard;
  teamLeaderboard?: TeamLeaderBoard;
  teamTypes?: Array<TeamType>;
};

const GroupLeaderBoard = (props: GroupLeaderBoardProps) => {
  const {group, isLoading, teamTypes, teamLeaderboard} = props;
  const {teams, challengeType, previousLeaderboards} = group;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const [
    isPreviousLeaderboardVisible,
    openPreviousLeaderboard,
    closePreviousLeaderBoard,
  ] = useBoolean();

  const isOwnTeam = useCallback(
    ({team}: {team: Team}) => Boolean(team.highlight),
    [],
  );

  if (!isLoading && !teams.length) {
    return null;
  }

  const month = getMonthTranslation({translate});
  const title = translate(organizationConfig.translations.competition.title, {
    key: '{month}',
    value: month,
  });
  const subtitle = translate(
    organizationConfig.translations.competition.subtitle,
  );

  return (
    <>
      <LeaderboardWidget
        title={title}
        subtitle={subtitle}
        image={
          organizationConfig.theme.components.leaderboard?.groupBanner ||
          bannerImg
        }
        isLoading={isLoading}
        isOwnTeam={isOwnTeam}
        teams={teams}
        challengeType={challengeType}>
        <Cta>
          {teamTypes && teamLeaderboard && (
            <TeamSettings
              leaderboard={teamLeaderboard}
              teamTypes={teamTypes}
              onClose={() => {}}
            />
          )}
          {!!previousLeaderboards?.length && (
            <LeaderboardLink onClick={openPreviousLeaderboard}>
              {translate('sdk.web.teams.show.previous.results')}
            </LeaderboardLink>
          )}
        </Cta>
      </LeaderboardWidget>
      <PreviousLeaderboardPrompt
        isOwnTeam={isOwnTeam}
        isVisible={isPreviousLeaderboardVisible}
        previousLeaderboards={previousLeaderboards || []}
        onClose={closePreviousLeaderBoard}
      />
    </>
  );
};
export default GroupLeaderBoard;
