import React, {createContext, useCallback, useEffect, useState} from 'react';

import {getCachedProfile, saveProfileToCache} from 'services/cache';
import {initNotificationsAuth} from 'services/notifications';
import {getMyProfile, UserData, UserProfile} from 'services/user';

interface LoadUserProfile {
  reload?: boolean;
}

interface UpdateAuthToken {
  token: string;
}

export type AuthObject = {
  auth?: any;
  userProfile?: UserProfile;
  errorLoadingUserProfile: boolean;
  isLoadingUserProfile: boolean;
  isNotificationsInitialized?: boolean;
  addAuth?: (auth: any) => void;
  updateAuthToken: (data: UpdateAuthToken) => void;
  removeAuth?: () => void;
  setUserProfile?: (userProfile: any) => void;
  loadUserProfile: ({reload}: LoadUserProfile) => void;
  updateUserData: (updates: Partial<UserData>) => void;
};

export const AuthContext = createContext<AuthObject>({
  errorLoadingUserProfile: false,
  isLoadingUserProfile: false,
  loadUserProfile: () => true,
  updateAuthToken: () => true,
  updateUserData: () => true,
});

const authLocalStorageKey = 'auth';

const AuthProvider = (props: any) => {
  const [auth, setAuth] = useState<any>({});
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [isNotificationsInitialized] = useState(false);

  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(false);
  const [errorLoadingUserProfile, setErrorLoadingUserProfile] = useState(false);

  useEffect(() => {
    const existingAuth = localStorage.getItem(authLocalStorageKey);
    if (existingAuth) {
      try {
        const parsedAuth = JSON.parse(existingAuth);
        setAuth(parsedAuth);
      } catch (e: any) {}
    }
  }, []);

  const addAuth = (auth: any) => {
    setAuth({...auth});
    localStorage.setItem(authLocalStorageKey, JSON.stringify(auth));
    initNotificationsAuth();
  };

  const updateAuthToken = ({token}: UpdateAuthToken) => {
    const updatedAuth = {...auth, token};
    setAuth(updatedAuth);
    localStorage.setItem(authLocalStorageKey, JSON.stringify(updatedAuth));
  };

  const removeAuth = () => {
    setAuth(null);
    localStorage.removeItem(authLocalStorageKey);
  };

  const loadUserProfile = useCallback(
    async ({reload}: LoadUserProfile) => {
      if (isLoadingUserProfile || !auth?.token) {
        return;
      }

      if (!reload && userProfile?.user) {
        return;
      }

      const cachedProfile = getCachedProfile();
      if (cachedProfile) {
        setUserProfile(cachedProfile);
        setIsLoadingUserProfile(false);
      }

      setIsLoadingUserProfile(true);
      try {
        const {data: profile} = await getMyProfile();
        setUserProfile(profile);
        saveProfileToCache(profile);
      } catch (e: any) {
        setErrorLoadingUserProfile(true);
      } finally {
        setIsLoadingUserProfile(false);
      }
    },
    [isLoadingUserProfile, auth, userProfile],
  );

  const updateUserData = async (updates: Partial<UserData>) => {
    if (!userProfile) {
      return;
    }

    const profile = {
      ...userProfile,
      user: {
        ...userProfile?.user,
        ...updates,
      },
    };

    setUserProfile(profile);
    saveProfileToCache(profile);
  };

  const options = {
    auth,
    userProfile,
    isNotificationsInitialized,
    isLoadingUserProfile,
    errorLoadingUserProfile,
    addAuth,
    updateAuthToken,
    removeAuth,
    setUserProfile,
    loadUserProfile,
    updateUserData,
  };

  return (
    <AuthContext.Provider value={options}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
