import {fetchAPiClient} from './api';
import {TalerTransaction, TRANSFER_TYPE} from 'types/Taler';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

interface InitiateTransactionParams {
  amount: number;
  type: TRANSFER_TYPE;
}

export const initiateTransaction = async (
  data: InitiateTransactionParams,
): Promise<TalerTransaction> => {
  const client = defaultApiClient;
  const url = '/taler/transaction';
  return client.post(url, data);
};

interface GetTransactionParams {
  page: number;
  limit?: number;
}

export const getTransactions = async (params?: GetTransactionParams) => {
  const client = defaultApiClient;
  const url = '/taler/transaction';
  return client.get(url, {
    params,
  });
};
