import styled from 'styled-components';
import CtaLink from 'components/CtaLink/CtaLink';

const LeaderboardLink = styled(CtaLink)`
  color: ${(props) =>
    props.theme.components.leaderboard.ctaColor || props.theme.colors.black};
  margin-bottom: 10px;
`;

export default LeaderboardLink;
