import {fetchAPiClient} from './api';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export type RegisterData = {
  username: string;
  password: string;
};

export type SettingsData = {
  size: number;
  people: number;
};

export const connect = async (data: RegisterData) => {
  const client = defaultApiClient;
  const url = '/metering/connect';
  return client.post<any>(url, {...data, driver: 'diehl', type: 'water'});
};

export const disconnect = async () => {
  const client = defaultApiClient;
  const url = '/metering/disconnect';
  return client.post<any>(url, {driver: 'diehl', type: 'water'});
};

export const getStats = async () => {
  const client = defaultApiClient;
  const url = '/metering/user-stats';
  return client.get<any>(url, {
    params: {
      type: 'water',
    },
  });
};

export const getSettings = async () => {
  const client = defaultApiClient;
  const url = '/user-household-settings';
  const response = await client.get<any>(url);
  return response?.data || {size: '', people: ''};
};

export const updateSettings = async (data: SettingsData) => {
  const client = defaultApiClient;
  const url = '/user-household-settings';
  return client.post<any>(url, data);
};

export const getPreviousResults = async () => {
  const client = defaultApiClient;
  const url = '/metering/previous-results';
  return client.get<any>(url, {
    params: {
      type: 'water',
    },
  });
};
