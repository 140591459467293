import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {TRANSACTION_TYPES} from 'constants/transaction-type';
import {useTranslations} from 'hooks/use-translations';
import {formatNumber, numberToKm} from 'utils/numbers';
import {getMonthKey, getMonthKeyTranslation} from 'utils/date';

import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import TeamRanking from 'components/ClimateWidgets/Leaderboard/TeamRanking/TeamRanking';
import {LeaderBoard, Team} from 'types/Team';

const TotalWrapper = styled.div`
  margin-bottom: 15px;
`;

const Text = styled(CopyText)`
  margin-bottom: 12px;
  display: block;
  line-height: ${(props) => props.theme.font.styles.normal.fontSize * 1.4}px;
`;

type LeaderBoardProps = {
  leaderboard: LeaderBoard;
  previousLeaderboard?: LeaderBoard;
  isOwnTeam?: ({team}: {team: Team}) => boolean;
};

const Leaderboard = (props: LeaderBoardProps) => {
  const {leaderboard, previousLeaderboard} = props;
  const {translate} = useTranslations();

  const {teams} = leaderboard;
  const isOwnTeam = useCallback(({team}: {team: Team}) => !!team.users?.me, []);

  const endDate = new Date(leaderboard?.end_date?.toString().split('T')[0]);
  const month = getMonthKeyTranslation({
    translate,
    monthKey: getMonthKey(endDate.getMonth()),
  });
  const title = translate('sdk.web.teams.previous.results.title', {
    key: '{month}',
    value: month,
  });

  const percentageChange = useMemo(() => {
    if (!previousLeaderboard?.total_co2_avoided) {
      return '';
    }

    const percentage =
      ((leaderboard.total_co2_avoided -
        previousLeaderboard?.total_co2_avoided) /
        previousLeaderboard?.total_co2_avoided) *
      100;

    if (percentage >= 0 && percentage < 1) {
      return translate('sdk.web.teams.previous.results.percentage.same');
    }

    const translationKey =
      percentage > 0
        ? 'sdk.web.teams.previous.results.percentage.increase'
        : 'sdk.web.teams.previous.results.percentage.decrease';

    const formattedPercent = formatNumber({
      number: Math.abs(percentage),
      dp: 0,
    });

    return translate(translationKey, {
      key: '{percent}',
      value: formattedPercent,
    });
  }, [translate, leaderboard, previousLeaderboard]);

  const activitySummary = useMemo(() => {
    if (!leaderboard.activities) {
      return '';
    }

    const activities = leaderboard.activities.map(({key, value}) => {
      const formatter =
        key === TRANSACTION_TYPES.TREE_PLANTED.key ? formatNumber : numberToKm;

      return {
        key: `{${key}}`,
        value: formatter({
          number: value,
          dp: 0,
        }),
      };
    });

    activities.push({
      key: '{co2Savings}',
      value: numberToKm({number: leaderboard.total_co2_avoided, dp: 0}),
    });

    return translate('sdk.web.teams.previous.results.activity', activities);
  }, [translate, leaderboard]);

  return (
    <div>
      <Title>{title}</Title>
      <TotalWrapper>
        <Text>{activitySummary}</Text>
        {percentageChange && <Text>{percentageChange}</Text>}
      </TotalWrapper>
      <TeamRanking
        teams={teams}
        isOwnTeam={props.isOwnTeam || isOwnTeam}
        challengeType={leaderboard.challengeType}
      />
    </div>
  );
};

export default Leaderboard;
