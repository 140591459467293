import axios from 'axios';

import {NATIVE_MESSAGES} from 'constants/native-events';
import {getCachedProfile} from 'services/cache';
import {
  apiErrorMap,
  TOKEN_EXPIRED,
  LOGIN_REQUIRED,
} from 'constants/api-errors';
import {sendMessageToNativeApp} from 'services/native-api';
import {DomainContext, getAuthConfig} from 'services/auth';
import {AccountType} from 'types/User';
import {getDeviceLanguage} from 'utils/language';

const language = getDeviceLanguage();

let tokenExpired = false;
let lastExpiredTokenTimestamp = 0;

const TOKEN_EXPIRY_THRESHOLD = 5000; // Milliseconds

interface FetchAPiClient {
  subdomain?: string;
  context?: DomainContext;
  guest?: boolean;
  rootPath?: string;
  ignoreForbiddenErrors?: boolean;
}

export const fetchAPiClient = ({
  subdomain = 'sst',
  context,
  guest,
  ignoreForbiddenErrors,
  rootPath = 'api',
}: FetchAPiClient) => {
  const defaultOptions = {
    baseURL: `https://${subdomain}.${process.env.REACT_APP_BASE_DOMAIN}/${rootPath}`,
    headers: {
      Accept: 'application/json',
      'accept-language': language,
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${token}`,
    },
  };
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use((config) => {
    if (!config.data) {
      config.data = {};
    }

    const {baseDomain, headers} = getAuthConfig({context});

    if (baseDomain) {
      // @ts-ignore
      config.baseURL = `https://${subdomain}.${baseDomain}/${rootPath}`;
    }

    config.headers = {
      ...config.headers,
      ...headers,
    };

    if (guest) {
      // delete config.headers.Authorization;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      tokenExpired = false;
      return response.data || response;
    },
    (error) => {
      let errorResponseOverride;
      if (
        !ignoreForbiddenErrors &&
        (error?.response?.status === 403 || error?.response?.status === 401) &&
        !tokenExpired && error?.response?.data?._details === 2002
      ) {
        tokenExpired = true;
        const previousExpiredTokenTimestamp = lastExpiredTokenTimestamp;
        lastExpiredTokenTimestamp = Date.now();

        // Request new token if the last expired token is more than TOKEN_EXPIRY_THRESHOLD
        // in order to guard against intermittent network issues
        if (
          lastExpiredTokenTimestamp - previousExpiredTokenTimestamp >
          TOKEN_EXPIRY_THRESHOLD
        ) {
          const userProfile = getCachedProfile();

          if (userProfile?.user.account_type === AccountType.USER) {
            const message = {
              type: NATIVE_MESSAGES.REQUEST_NEW_TOKEN,
            };
            sendMessageToNativeApp({message});

            errorResponseOverride = {
              data: {
                errorKey: LOGIN_REQUIRED,
                errorMessage: '',
              },
            };
          } else {
            sendMessageToNativeApp({
              message: {type: NATIVE_MESSAGES.REQUEST_FRESH_TOKEN},
            });
          }
        }
      }

      if (errorResponseOverride) {
        error.response = errorResponseOverride;
      }
      // Ignore error if token is expired
      else if (tokenExpired) {
        error.response = {
          data: {
            errorKey: TOKEN_EXPIRED,
            errorMessage: '',
          },
        };
      } else if (
        error?.response?.data?._details ||
        error?.response?.data?._status
      ) {
        if (typeof error?.response.data._details === 'object') {
          const detailErrors = error?.response.data._details;
          error.response.data.fieldErrorKeys = Object.keys(detailErrors).reduce(
            (errorKeys: any, key: string) => {
              if (detailErrors[key]?.length) {
                errorKeys[key] = detailErrors[key].map(
                  (errorCode: number) => apiErrorMap[errorCode],
                );
              }

              return errorKeys;
            },
            {} as any,
          );

          const errorCode = error?.response.data.status as number;
          error.response.data.errorKey = apiErrorMap[errorCode];
        } else {
          const errorCode = (error?.response.data._details ||
            error?.response.data.status) as number;
          error.response.data.errorKey = apiErrorMap[errorCode];
        }
      } else if (error?.response?.data?.code) {
        error.response.data.errorKey = apiErrorMap[error.response.data.code];
        error.response.data.errorMessage = error.response.data.message;
      } else {
        console.log('Error URL: ', error?.config?.url);
      }

      return Promise.reject(error);
    },
  );
  return instance;
};
