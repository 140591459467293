import {AccountType} from 'types/User';
import {fetchAPiClient} from './api';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export type SignupData = {
  account_type: AccountType;
  firstname?: string;
  lastname?: string;
  email: string;
  password: string;
};

interface Signup {
  id: number;
  data: SignupData;
}

export const signup = async ({id, data}: Signup) => {
  const client = defaultApiClient;
  const url = `/user/${id}/convert`;
  return client.post<any>(url, data);
};

export const getMyProfile = () => {
  const client = defaultApiClient;
  const url = '/user/me';
  return client.get<any>(url);
};

export type LoginData = {
  email: string;
  password: string;
};

interface Login {
  data: LoginData;
}

export const login = async ({data}: Login) => {
  const client = defaultApiClient;
  const url = '/login';
  return client.post<any>(url, data);
};

export type PasswordResetData = {
  email: string;
};

interface ResetPassword {
  data: PasswordResetData;
}

export const resetPassword = async ({data}: ResetPassword) => {
  const client = defaultApiClient;
  const url = '/forgot-password';
  return client.post<any>(url, data);
};

export type EmailChangeData = {
  email: string;
};

interface EmailChange {
  data: EmailChangeData;
  id: number;
}

export const changeEmail = async ({data, id}: EmailChange) => {
  const client = defaultApiClient;
  const url = `/user/${id}`;
  return client.put(url, data);
};

export type ProfileUpdateData = {
  firstname: string;
  lastname: string;
};

interface ProfileUpdate {
  data: ProfileUpdateData;
  id: number;
}

export const updateProfile = async ({data, id}: ProfileUpdate) => {
  const client = defaultApiClient;
  const url = `/user/${id}`;
  return client.put(url, data);
};

export const changePassword = async () => {
  const client = defaultApiClient;
  const url = '/change-password';
  return client.post<any>(url);
};

interface LogoutParams {
  timeout: number;
}

export const logout = async ({timeout}: LogoutParams) => {
  const url = '/logout';

  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeout);

  await defaultApiClient.post<any>(url, null, {
    timeout,
    signal: abortController.signal,
  });
};

interface DeleteAccount {
  id: number;
}

export const deleteAccount = async ({id}: DeleteAccount) => {
  const client = defaultApiClient;
  const url = `/user/${id}`;
  return client.delete(url);
};

export type UpdateGuestDetailsParams = {
  id: number;
  data: {
    guest_firstname: string;
    guest_lastname: string;
    guest_email: string;
  };
};
export const updateGuestDetails = async ({
  id,
  data,
}: UpdateGuestDetailsParams) => {
  const client = defaultApiClient;
  const url = `/user/${id}`;
  return client.put(url, data);
};
