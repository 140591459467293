import React, {createContext, useCallback, useState} from 'react';
import pick from 'lodash/pick';

import {Team} from 'types/Team';

type UpdatedCurrentTeams = ({
  teamTypeId,
  team,
}: {
  teamTypeId: number;
  team: Team;
}) => void;

type RemoveCurrentTeam = ({teamTypeId}: {teamTypeId: number}) => void;

type SelectedTeam = Record<number, Partial<Team>>;

type TeamObject = {
  currentTeams: SelectedTeam;
  updateCurrentTeams?: UpdatedCurrentTeams;
  removeCurrentTeam?: RemoveCurrentTeam;
};

export const TeamContext = createContext<TeamObject>({currentTeams: {}});

const TeamProvider = (props: any) => {
  const [currentTeams, setCurrentTeams] = useState<SelectedTeam>({});

  const updateCurrentTeams: UpdatedCurrentTeams = useCallback(
    ({teamTypeId, team}) => {
      setCurrentTeams((currentTeams) => {
        if (!team) {
          delete currentTeams[teamTypeId];
          return currentTeams;
        }

        currentTeams[teamTypeId] = pick(team, [
          'id',
          'name',
          'co2_avoided',
          'position',
          'passphrase_required',
        ]);
        return currentTeams;
      });
    },
    [],
  );

  const removeCurrentTeam: RemoveCurrentTeam = useCallback(({teamTypeId}) => {
    setCurrentTeams((currentTeams) => {
      delete currentTeams[teamTypeId];
      return currentTeams;
    });
  }, []);

  const value = {
    currentTeams,
    removeCurrentTeam,
    updateCurrentTeams,
  };

  return (
    <TeamContext.Provider value={value}>{props.children}</TeamContext.Provider>
  );
};

export default TeamProvider;
