import React, {useCallback, useEffect, useState} from 'react';
import times from 'lodash/times';
import isUndefined from 'lodash/isUndefined';
import styled from 'styled-components';
import {getCurrentMonth, getMonthKey, getMonthKeyTranslation} from 'utils/date';
import {useTranslations} from 'hooks/use-translations';
import TextXs from 'components/Text/TextXs/TextXs';

const monthList = times(12, Number);

const SelectBlock = styled(TextXs)`
  position: relative;
  display: flex;
`;

const SelectValue = styled.div`
  display: block;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary};
`;

const SelectDropdown = styled.select`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

type MonthPickerProps = {
  initialValue?: number;
  onMonthChange: (month: number) => void;
};

const MonthPicker = ({initialValue, onMonthChange}: MonthPickerProps) => {
  const {translate} = useTranslations();
  const [selectedMonth, setSelectedMonth] = useState(
    isUndefined(initialValue) ? getCurrentMonth() : initialValue,
  );

  useEffect(() => onMonthChange(selectedMonth), [selectedMonth, onMonthChange]);

  const handleMonthChange = useCallback((e: any) => {
    setSelectedMonth(e.target.value);
  }, []);

  return (
    <div>
      <SelectBlock>
        <SelectValue>
          {getMonthKeyTranslation({
            translate,
            monthKey: getMonthKey(selectedMonth),
          })}
        </SelectValue>
        <SelectDropdown onChange={handleMonthChange} value={selectedMonth}>
          {monthList.map((month: number) => (
            <option key={month} value={month}>
              {getMonthKeyTranslation({
                translate,
                monthKey: getMonthKey(month),
              })}
            </option>
          ))}
        </SelectDropdown>
      </SelectBlock>
    </div>
  );
};

export default MonthPicker;
