import {TeamType, TeamTypes} from 'types/Team';

export const getTeamsFromTeamTypes = ({
  teamTypes,
  teamTypeId,
}: {
  teamTypes: TeamTypes;
  teamTypeId: number;
}) =>
  teamTypes.find((teamType: TeamType) => teamType.id === teamTypeId)?.teams ||
  [];
