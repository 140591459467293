import React, {useCallback, useMemo} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {initiateTransaction} from 'services/taler';
import {TalerTransaction, TRANSFER_TYPE} from 'types/Taler';
import {formatToken} from 'utils/numbers';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import DialogBox from 'components/DialogBox/DialogBox';
import Input from 'components/Form/Input';
import PromptWrapper, {
  Title,
} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;

  .trx-modal {
    &__title {
      margin-bottom: 30px;
    }

    &__coins {
      margin-bottom: 10vh;
    }

    &__form-group {
      label {
        margin-bottom: 10px;
      }

      // text-align: left;

      input {
        text-align: center;
      }
    }
  }
`;

interface TransferModalProps {
  isVisible: boolean;
  transferType: TRANSFER_TYPE;
  coinBalance: number;
  onComplete: (transaction: TalerTransaction) => any;
  onClose: () => any;
}

const transferConfig = {
  [TRANSFER_TYPE.SEND]: {
    confirmation: {
      title: 'sdk.web.gnu.taler.trf.send.prompt.title',
      text: 'sdk.web.gnu.taler.trf.send.prompt.text',
    },
    infoText: '',
    successText: 'sdk.web.gnu.taler.trf.send.prompt.success',
    cta: 'sdk.web.gnu.taler.trf.send.prompt.cta',
    maxCoins: null,
    showAvailableCoins: true,
  },
  [TRANSFER_TYPE.RECEIVE]: {
    confirmation: {
      title: 'sdk.web.gnu.taler.trf.receive.prompt.title',
      text: 'sdk.web.gnu.taler.trf.receive.prompt.text',
    },
    infoText: '',
    successText: 'sdk.web.gnu.taler.trf.receive.prompt.success',
    cta: 'sdk.web.gnu.taler.trf.receive.prompt.cta',
    maxCoins: 100,
    showAvailableCoins: false,
  },
};

const TransferModal = (props: TransferModalProps) => {
  const {isVisible, coinBalance, transferType, onComplete, onClose} = props;
  const {translate} = useTranslations();
  const {getTokenText, getShortTokenText} = useTokenTranslation();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const toast = useToast();

  const [isConfirmationOpen, openConfirmation, closeConfirmation] =
    useBoolean();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();

  const validationSchema = useMemo(() => {
    const coinsWithDp = formatToken({
      number: coinBalance,
      ignoreThousandRule: true,
      toNumber: true,
    }) as number;
    const token = getShortTokenText(Math.floor(coinsWithDp));
    const fieldSub = {
      key: '{field}',
      value: token,
    };

    const maxAmount = transferConfig[transferType].maxCoins || coinsWithDp;

    return yup.object().shape({
      amount: yup
        .number()
        .required(translate('sdk.web.error.field.required', fieldSub))
        .min(1, translate('sdk.web.error.field.min', fieldSub))
        .max(maxAmount, translate('sdk.web.error.field.max', fieldSub))
        .test(
          'is-whole-number',
          translate('sdk.web.gnu.taler.trf.warning.coins.whole', {
            key: '{tokens}',
            value: getTokenText(2),
          }),
          (v) => Number.isInteger(v),
        ),
    });
  }, [coinBalance, getShortTokenText, getTokenText, transferType, translate]);

  const handleTransfer = useCallback(
    async ({transfer, amount}: {transfer: boolean; amount: number}) => {
      if (!transfer) {
        closeConfirmation();
        return;
      }

      startSubmitting();
      try {
        const transaction = await initiateTransaction({
          amount,
          type: transferType,
        });

        closeConfirmation();
        onComplete(transaction);
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const fieldErrorKeys = e.response
          ? e.response?.data?.fieldErrorKeys
          : undefined;
        const message = translate(
          errorKey || 'sdk.web.gnu.taler.trf.error.fallback',
        );
        toast.error(message);
        setApiErrors(fieldErrorKeys);
      } finally {
        stopSubmitting();
      }
    },
    [
      transferType,
      toast,
      translate,
      startSubmitting,
      stopSubmitting,
      closeConfirmation,
      setApiErrors,
      onComplete,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues: {
      amount: '',
    },
    onSubmit: openConfirmation,
    validate,
  });

  return (
    <>
      <PromptWrapper isVisible={isVisible} onClose={onClose}>
        <Wrapper className="trx-modal" onSubmit={formik.handleSubmit}>
          <div>
            <Title className="trx-modal__title">
              {translate(transferConfig[transferType].cta)}
            </Title>
            {transferConfig[transferType].showAvailableCoins && (
              <div className="trx-modal__coins">
                {translate('sdk.web.gnu.taler.trf.available.coins')}:{' '}
                {coinBalance}
              </div>
            )}
            <div className="trx-modal__form-group">
              <label>
                <b>
                  {translate('sdk.web.gnu.taler.trf.label', {
                    key: '{tokens}',
                    value: getTokenText(2),
                  })}
                </b>
              </label>
              <Input
                name="amount"
                type="number"
                min="1"
                step="1"
                pattern="[0-9]"
                value={formik.values.amount}
                formik={formik}
                apiErrors={apiErrors}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div>
            {transferConfig[transferType].infoText && (
              <CopyTextSm as="div" className="mb-base">
                {translate(transferConfig[transferType].infoText, {
                  key: '{tokens}',
                  value: getTokenText(2),
                })}
              </CopyTextSm>
            )}
            <Button className="mb-base" isLoading={isSubmitting}>
              {translate(transferConfig[transferType].cta)}
            </Button>
            <CtaLink noMargin onClick={onClose}>
              {translate('sdk.web.gnu.taler.trf.cancel')}
            </CtaLink>
          </div>
        </Wrapper>
      </PromptWrapper>
      {isConfirmationOpen && (
        <DialogBox
          title={translate(transferConfig[transferType].confirmation.title)}
          promptMessage={translate(
            transferConfig[transferType].confirmation.text,
          )}
          noText={translate('sdk.web.dialog.box.cancel')}
          yesText={translate('sdk.web.dialog.box.confirm')}
          isLoading={isSubmitting}
          onConfirmation={(transfer) =>
            handleTransfer({transfer, amount: +formik.values.amount})
          }
        />
      )}
    </>
  );
};

export default TransferModal;
