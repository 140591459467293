import React from 'react';
import styled from 'styled-components';
import {FormikContextType} from 'formik';

import {useTranslations} from 'hooks/use-translations';
import {Team} from 'types/Team';

import CopyText from 'components/Text/CopyText/CopyText';
import Button from 'components/Button/Button';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';

import Codeword from './Codeword';

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const Text = styled(CopyText)`
  display: block;
`;

interface PassphraseModalProps {
  team: Team;
  formik: FormikContextType<any>;
  onChange: any;
  onClose: () => void;
  isVisible: boolean;
}

const PassphraseModal = (props: PassphraseModalProps) => {
  const {team, formik, onChange, onClose, isVisible} = props;
  const {translate} = useTranslations();

  return (
    <PromptWrapper title={team.name} isVisible={isVisible} onClose={onClose}>
      <Wrapper>
        <Text>
          <CopyText
            dangerouslySetInnerHTML={{
              __html: translate('sdk.web.teams.passphrase.hint'),
            }}
          />
        </Text>
        <Codeword formik={formik} onChange={onChange} />
        <Button onClick={() => formik.handleSubmit()}>
          {translate('sdk.web.teams.passphrase.modal.cta')}
        </Button>
      </Wrapper>
    </PromptWrapper>
  );
};

export default PassphraseModal;
