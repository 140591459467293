import React, {useContext} from 'react';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';

import InfoPage from 'components/InfoPage/InfoPage';

const Map = React.lazy(() => import('pages/ClimateTreasure/Map'));

const ClimateTreasure = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CLIMATE_TREASURE,
  });
  const {translate} = useTranslations();

  const title = translate(
    organizationConfig.translations.climateTreasure?.title ||
      'sdk.web.treasure.map.title',
  );

  return (
    <InfoPage title={title} noPadding onClose={handleGoBack}>
      <Map />
    </InfoPage>
  );
};

export default ClimateTreasure;
