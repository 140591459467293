import React, {useCallback, useMemo, useRef} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import pick from 'lodash/pick';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useToast} from 'hooks/use-toast';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useForm} from 'hooks/forms/use-form';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {updateGuestDetails, UpdateGuestDetailsParams} from 'services/account';

import CopyText from 'components/Text/CopyText/CopyText';
import FormRow from 'components/Form/FormRow';
import Input from 'components/Form/Input';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import PromptModal from 'components/PromptModal/PromptModal';

const Wrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;

  .lots-form {
    &__group {
      text-align: left;
      margin: 40px 0 20px;
    }

    &__info {
      margin-top: 15px;
    }
  }
`;

interface LotteryFormProps {
  title: string;
  subtitle: string;
  showSuccess?: boolean;
  usePrompt?: boolean;
  onClose: () => void;
  onComplete?: () => void;
}

const LotteryForm = (props: LotteryFormProps) => {
  const {title, subtitle, showSuccess, usePrompt, onClose, onComplete} = props;
  const {translate} = useTranslations();
  const {userProfile, reloadUserProfile} = useUserProfile();
  const toast = useToast();

  const formRef = useRef<HTMLFormElement>(null);
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const {getKeyDownListener} = useForm();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();
  const [isSuccessModalOpen, showSuccessModal] = useBoolean();

  const Container = useMemo(
    () => (usePrompt ? PromptWrapper : React.Fragment),
    [usePrompt],
  );

  const {validationSchema, initialValues} = useMemo(() => {
    const validationSchema = yup.object().shape({
      guest_firstname: yup
        .string()
        .required(translate('sdk.web.validations.firstName.required'))
        .max(255, translate('sdk.web.validations.text.max.characters'))
        .nullable(),
      guest_lastname: yup
        .string()
        .required(translate('sdk.web.validations.lastName.required'))
        .max(255, translate('sdk.web.validations.text.max.characters'))
        .nullable(),
      guest_email: yup
        .string()
        .required(translate('sdk.web.validations.email.required'))
        .email(translate('sdk.web.contact.form.email.error.invalid'))
        .nullable(),
    });

    const initialValues = pick(userProfile!.user, [
      'guest_firstname',
      'guest_lastname',
      'guest_email',
    ]);

    return {validationSchema, initialValues};
  }, [translate, userProfile]);

  const onSubmit = useCallback(
    async (data: UpdateGuestDetailsParams['data']) => {
      startSubmitting();

      try {
        await updateGuestDetails({id: userProfile!.user.id, data});
        await reloadUserProfile();
        if (showSuccess) {
          showSuccessModal();
        } else {
          toast.success(translate('sdk.web.lots.form.success'));
          onComplete && onComplete();
        }
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.lot.form.error.fallback',
        );
        toast.error(message);

        const fieldErrorKeys = e.response
          ? e.response?.data?.fieldErrorKeys
          : undefined;
        setApiErrors(fieldErrorKeys);
      } finally {
        stopSubmitting();
      }
    },
    [
      showSuccess,
      showSuccessModal,
      onComplete,
      userProfile,
      reloadUserProfile,
      toast,
      translate,
      setApiErrors,
      startSubmitting,
      stopSubmitting,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <Container title={title} onClose={onClose} isVisible={true}>
      <Wrapper
        className="lots-form"
        onSubmit={formik.handleSubmit}
        ref={formRef}>
        <CopyText as="div">{subtitle}</CopyText>
        <div className="lots-form__group">
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.firstName.label')}
              name="guest_firstname"
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.guest_firstname}
              onChange={formik.handleChange}
              enterkeyhint="next"
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
          </FormRow>
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.lastName.label')}
              name="guest_lastname"
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.guest_lastname}
              onChange={formik.handleChange}
              enterkeyhint="next"
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
          </FormRow>
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.email.label')}
              name="guest_email"
              type="email"
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.guest_email}
              onChange={formik.handleChange}
              enterkeyhint="go"
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
            <div className="lots-form__info">
              {translate('sdk.web.lots.form.info')}
            </div>
          </FormRow>
        </div>
        <div>
          <Button
            className={classnames({
              'mb-base': showSuccess,
            })}
            type="submit"
            isLoading={isSubmitting}>
            {translate('sdk.web.lots.form.cta')}
          </Button>
          {showSuccess && (
            <CtaLink onClick={onClose} noMargin>
              {translate('sdk.web.lots.form.skip')}
            </CtaLink>
          )}
        </div>
      </Wrapper>
      <PromptModal
        isVisible={isSuccessModalOpen}
        title={translate('sdk.web.lots.form.success.title')}
        textContent={translate('sdk.web.lots.form.success.subtitle')}
        btnText={translate('sdk.web.dialog.box.ok')}
        onBtnClick={onComplete}
        onClose={onComplete}
      />
    </Container>
  );
};

export default LotteryForm;
