import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {createPortal} from 'react-dom';

import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useOrganization} from 'hooks/use-organization';
import {Voucher} from 'types/Offers';
import {
  addBoldText,
  addFontFamily,
  addGlobalCardBorder,
  addGlobalSquareCorners,
} from 'utils/theme';

import Modal from 'components/Modal/Modal';
import {ReactComponent as Close} from 'components/SummaryPage/images/close.svg';

const ContentWrapper = styled.div<{bgColor: string}>`
  background: ${(props) => props.bgColor};
  position: relative;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  text-align: center;
`;

const CloseIcon = styled.div`
  fill: ${(props) =>
    props.theme.components.vouchers?.active?.closeIconColor ||
    props.theme.colors.black};
  cursor: pointer;
  width: 25px;
  margin-bottom: 20px;
  position: absolute;
  left: 10px;
`;

const Header = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;

  ${addGlobalSquareCorners()}
  ${addGlobalCardBorder()}

  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.vouchers?.active?.headerFontFamily,
    })}
`;

const CompanyName = styled.div`
  display: block;
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  padding: 0 30px;
  color: ${(props) => props.theme.components.vouchers?.active?.companyColor};
`;

const Logo = styled.img`
  width: 100%;
  height: 178px;
  object-fit: cover;
`;

const Title = styled.div<{isExpired: boolean}>`
  display: block;
  text-align: center;
  padding: 10px;
  font-size: 30px;
  line-height: 36px;

  background-color: ${(props) =>
    props.theme.components.vouchers?.active?.titleBgColor ||
    (props.isExpired
      ? props.theme.components.vouchers?.active
        ? '#F19298'
        : ''
      : '')};

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  color: ${(props) =>
    props.theme.components.vouchers.offlinePageColor ||
    props.theme.colors.black};
`;

interface PartnerPromptProps {
  voucher: Voucher;
  status: 'pending' | 'active' | 'expired';
  onClose?: () => void;
  children: React.ReactNode;
}

const PartnerPrompt = (props: PartnerPromptProps) => {
  const {voucher, status, onClose, children} = props;
  const {organizationConfig} = useOrganization();

  const bgColor = useMemo(() => {
    const newColorKey = `${status}BgColor`;
    return (
      organizationConfig.theme.components.vouchers?.active?.[newColorKey] ||
      organizationConfig.theme.components.vouchers.offlinePageBgColor ||
      organizationConfig.theme.colors.secondary
    );
  }, [status, organizationConfig]);

  const {updateBg} = useBodyBg({
    color: bgColor,
  });

  useEffect(() => {
    updateBg({color: bgColor});
  }, [bgColor, updateBg]);

  return createPortal(
    <Modal>
      <ContentWrapper bgColor={bgColor}>
        <CloseIcon onClick={onClose}>
          <Close />
        </CloseIcon>
        <CompanyName>{voucher.climate_partners?.[0]?.name}</CompanyName>
        <Header>
          <Logo src={voucher.image_file.url} />
          <Title isExpired={status === 'expired'}>{voucher.title}</Title>
        </Header>
        <Content>{children}</Content>
      </ContentWrapper>
    </Modal>,
    document.body,
  );
};

export default PartnerPrompt;
