import {fetchAPiClient} from './api';

const activityApiClient = fetchAPiClient({subdomain: 'activity'});

interface OffsetParams {
  recoins_spent: number;
  co2_pending: number;
}

type OffsetResponse = {
  recoins_spent: number;
  co2_offset: number;
  user_id: number;
  organisation_id: number;
  id: number;
  co2_emissions: number;
  co2_pending: number;
  recoins_available: number;
};

export const offset = async (data: OffsetParams): Promise<OffsetResponse> => {
  const url = '/offset';
  return await activityApiClient.post<OffsetParams, OffsetResponse>(url, data);
};
