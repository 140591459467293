import {isAndroid} from 'react-device-detect';

import HammCoin from 'components/ClimateWidgets/Co2Token/HammCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/HammCoin/images/coins/full.svg';
import {ReactComponent as Co2TokenInfoIcon} from 'components/ClimateWidgets/Co2Token/images/hamm/info.svg';

import onboardingOneImg from 'components/Onboarding/images/hamm/onboarding-1.svg';
import onboardingTwoImg from 'components/Onboarding/images/hamm/onboarding-2.svg';
import onboardingThreeImg from 'components/Onboarding/images/hamm/onboarding-3.svg';
import onboardingGpsImg from 'components/Onboarding/images/hamm/onboarding-gps.svg';
import onboardingMotionImg from 'components/Onboarding/images/hamm/onboarding-motion.svg';
import onboardingTeamsImg from 'components/Onboarding/images/hamm/onboarding-teams.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/hamm/onboarding-privacy.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/hamm/banner.svg';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/hamm/banner.svg';

import customActivitiesImgDe from 'components/ClimateWidgets/Mobility/images/hamm/custom-activities-de.svg';
import cyclingImg from 'components/ClimateWidgets/Mobility/images/hamm/cycling.svg';
import publicTransitImg from 'components/ClimateWidgets/Mobility/images/hamm/public-transit.svg';
import walkingImg from 'components/ClimateWidgets/Mobility/images/hamm/walking.svg';

import inviteFriendsSuccessBanner from 'components/ClimateWidgets/InviteFriends/InviteFriendsSuccessPrompt/images/hamm/banner.svg';

import {ReactComponent as LocationLinkIcon} from 'icons/hamm/location-link.svg';
import {ReactComponent as AllMobilityIcon} from 'components/ClimateWidgets/Leaderboard/images/hamm/all-mobility.svg';

const config = {
  name: 'hamm',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#591E60',
      'primary-300': '#C00D62',
      secondary: '#F6F6F6',
      tertiary: '#409DA9',
      darkBg: '#30454A',
      white: '#ffffff',
      black: '#3C3C3C',
      fullBlack: '#000000',
      gray: '#D8D9DA',
      green: '#cdd516',
      disabled: '#dee2e6',
      navMenu: '#ffffff66',
      promptBg: '#FFFFFF',
      promptColor: '#000000',
      promptOverlayBg: '#00000099',
      onboardingBg: '#FFFFFF',
    },
    components: {
      global: {
        borderColor: 'primary',
        cardBorderColor: 'primary',
      },
      dashboard: {},
      competition: {},
      nav: {
        bgColor: 'white',
        color: 'fullBlack',
        activeColor: 'primary-300',
        useLineIcon: true,
        inactiveOpacity: 1,
      },
      widgetCard: {
        bgColor: 'white',
        titleBgColor: 'primary',
        titleColor: 'white',
        textColor: 'fullBlack',
      },
      widgetTitle: {
        titleColor: 'primary-300',
      },
      offers: {
        activeTabBgColor: 'primary',
        navColor: 'primary-300',
        locationTypeColor: 'primary-300',
        /*
        navBgColor: 'gradient3',
        bodyBgColor: 'gradient2',
        activeTabBgColor: 'primary',
        recoinsLeftBgColor: 'green',
        recoinsRequiredBgColor: 'primary',
        recoinsTextColor: 'white',
        locationTypeBgColor: 'catSkillWhite',

         */
        LocationLinkIcon,
      },
      climatePartnerMap: {
        bgColor: 'white',
      },
      badges: {
        allLinkColor: 'primary',
        titleColor: 'primary-300',
      },
      treePlanting: {
        newTreeBanner: treePlantingNewTreeImage,
        goalBanner: treePlantingGoalImage,
        progressPathColor: '#38BDE4',
        progressBgColor: '#FFFFFF',
      },
      links: {
        noUnderline: true,
        textColor: 'primary',
      },
      buttons: {
        primary: {
          bgColor: 'primary',
          disabledBgColor: 'disabled',
          color: 'white',
        },
        gray: {
          bgColor: 'gray',
          color: 'white',
        },
        link: {
          bgColor: 'white',
          color: 'primary',
          borderRadius: 'none',
        },
      },
      mobilityIcons: {
        progressPathColor: 'primary-300',
        walking: {
          bgColor: 'transparent',
          img: walkingImg,
        },
        cycling: {
          bgColor: 'transparent',
          img: cyclingImg,
        },
        publicTransit: {
          bgColor: 'transparent',
          img: publicTransitImg,
        },
        customActivities: {
          bgColor: 'transparent',
          en: {
            img: customActivitiesImgDe,
          },
          de: {
            img: customActivitiesImgDe,
          },
        },
      },
      prompts: {
        bgColor: 'white',
        textColor: 'fullBlack',
        overlayBgColor: '#00000099',
      },
      customActivities: {
        titleColor: 'primary-300',
        detailsBgColor: 'secondary',
        detailsHeaderColor: 'primary-300',
        listBgColor: 'white',
        /*
        listBgColor: 'white',
        listTextColor: 'black',
        activeCheckColor: 'primary',
        caretColor: 'primary',
        detailsFooterBgColor: 'gradient2',
        detailsFooterColor: 'white',

         */
      },
      settings: {
        itemCaretColor: 'fullBlack',
        itemBorderColor: 'primary-300',
        bgColor: 'white',
      },
      co2Compensation: {
        pageBgColor: 'secondary',
        emptySunBgColor: 'secondary',
      },
      leaderboard: {
        bgColor: 'white',
        ctaColor: 'primary',
        dotsColor: 'primary',
        mobilityIcons: {
          All: AllMobilityIcon,
        },
      },
      teams: {
        selectBorderColor: 'primary',
        caretColor: 'primary',
        banner: onboardingTeamsImg,
      },
      inviteFriends: {
        progressBarColor: 'primary-300',
        progressBarBgColor: 'secondary',
        shareIconColor: 'primary',
        successBanner: inviteFriendsSuccessBanner,
      },
      vouchers: {
        wishlistIcon: {
          fillColor: 'primary-300',
        },
        active: {
          activeBgColor: 'secondary',
          pendingBgColor: 'secondary',
          expiredBgColor: '#FFAFAF',
          titleBgColor: 'white',
          progressColor: '#00930E',
          progressTrailColor: '#c7c7c7',
          closeIconColor: 'primary',
        },
        /*
        bgColor: 'white',
        textColor: 'white',
        recoinsTagBgColor: 'primary',
        recoinsLeftBgColor: 'green',
        active: {
          activeBgColor: 'gradient1',
          pendingBgColor: 'gradient1',
          expiredBgColor: 'gradient1',
          progressColor: 'primary',
          companyColor: 'white',
          closeIconColor: 'white',
        },
        online: {
          codeColor: 'primary',
        },
        offlinePageBg: 'gradient2',
        offlinePageColor: 'white',

         */
      },
      lots: {
        cardBgColors: {
          ongoing: '#FFFFFF',
          won: '#F9CA61',
          lost: '#D0D0D0',
        },
      },
      news: {
        bgColor: 'white',
        titleBgColor: 'secondary',
        titleColor: 'black',
      },
      detailsNavigation: {
        bgColor: 'white',
        color: 'primary-300',
        iconColor: 'primary',
      },
      onboarding: {
        images: {
          one: {
            image: onboardingOneImg,
          },
          two: {
            image: onboardingTwoImg,
          },
          three: {
            image: onboardingThreeImg,
          },
          privacy: {
            image: onboardingPrivacyImg,
          },
          gps: {
            image: onboardingGpsImg,
          },
          motion: {
            image: onboardingMotionImg,
          },
          teams: {
            image: onboardingTeamsImg,
          },
        },
      },
      co2Token: {
        InfoIcon: Co2TokenInfoIcon,
      },
      summaryPage: {},
      form: {},
    },
    colors: {
      primary: '#591E60',
      secondary: '#F6F6F6',
      body: '#F6F6F6',
      white: '#ffffff',
      black: '#3C3C3C',
      navMenu: '#ffffff66',
      promptColor: '#FFFFFF',
      promptOverlayBg: '#00000099',
      onboardingBg: '#FFFFFF',
      teamRankingHighlight: '#FFF3D7',
    },
  },
  translations: {
    cityName: 'sdk.web.hamm.city.name',
    shortTokenSingular: 'sdk.web.hamm.token.short.singular',
    shortTokenPlural: 'sdk.web.hamm.token.short.plural',
  },
  features: {
    hideAppClose: !isAndroid,
    hideLotsTab: false,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: false,
    hasCustomActivities: true,
    hasBadges: true,
    hasUserRanking: true,
    hasNewQRScanner: true,
  },
  applangaTag: '620fa30a995af950f79dbe71',
  matomoTrackingId: 5,
  tokenType: 'HammCoin',
  fullTokenImg,
  FullTokenComponent: HammCoin,
};

export default config;
