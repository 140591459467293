import React, {useCallback, useContext, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import routes from 'config/routes';
import {CACHE_KEYS} from 'constants/cache-keys';
import {SettingsContext} from 'contexts/SettingsContext';
import {TeamContext} from 'contexts/TeamContext';
import {useTranslations} from 'hooks/use-translations';
import {useFetcher} from 'hooks/use-fetcher';
import {
  TeamLeaderBoard,
  getAllTeamTypes,
  getTeamLeaderBoards,
} from 'services/teams';
import {closeApp} from 'services/native-api';
import {Team, TeamType} from 'types/Team';

import Button from 'components/Button/Button';
import Onboarding from 'components/Onboarding/Onboarding';
import OnboardLink from 'components/Onboarding/OnboardLink';
import TeamDropdown from './TeamDropdown';

import teamImg from 'components/Onboarding/images/onboarding-team.svg';

const ButtonContainer = styled.div`
  display: block;
  margin-bottom: 20px;
`;

const OnboardingTeam = (props: any) => {
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {updateCurrentTeams} = useContext(TeamContext);

  const {data: teamTypes} = useFetcher<Array<TeamType>>({
    key: CACHE_KEYS.TEAM_TYPES,
    fetcher: getAllTeamTypes,
    initialValue: [],
  });

  const {data: leaderboards} = useFetcher<Array<TeamLeaderBoard>>({
    fetcher: getTeamLeaderBoards,
    params: {},
    initialValue: [],
    key: CACHE_KEYS.LEADERBOARD,
  });

  useEffect(() => {
    // Update team context with the current user's team
    leaderboards.forEach((leaderboard) => {
      const existingTeam = leaderboard.current?.teams.find(
        (team: Team) => !!team.users?.me,
      );

      if (existingTeam) {
        updateCurrentTeams!({
          teamTypeId: leaderboard.teamTypeId,
          team: existingTeam,
        });
      }
    });
  }, [leaderboards, updateCurrentTeams]);

  const handleNext = useCallback(async () => {
    props.history.push(routes.DASHBOARD.href);
  }, [props.history]);

  return (
    <Onboarding
      title={translate('sdk.web.onboarding.teams.title')}
      content=""
      onboardingImg={
        organizationConfig.theme.components.onboarding?.images?.teams?.image ||
        teamImg
      }
      imageOrder="4"
      ComponentBlock={teamTypes.map((teamType: TeamType) => (
        <TeamDropdown key={teamType.id} teamType={teamType} />
      ))}
      handleClose={organizationConfig.features.hideAppClose ? false : closeApp}>
      <ButtonContainer>
        <Button onClick={handleNext}>
          {translate('sdk.web.onboarding.teams.cta')}
        </Button>
      </ButtonContainer>
      <OnboardLink to={routes.DASHBOARD.href} noMargin>
        {translate('sdk.web.onboarding.skip.link')}
      </OnboardLink>
    </Onboarding>
  );
};

export default withRouter(OnboardingTeam);
