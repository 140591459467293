import {fetchAPiClient} from './api';
import {Challenge} from 'types/Challenge';

const activityApiClient = fetchAPiClient({subdomain: 'activity'});

export const getChallenges = () => {
  const client = activityApiClient;
  const url = '/statistics/challenges?page=1&limit=4';
  return client.get<any>(url);
};

export const getChallengeData = async (): Promise<Array<Challenge>> => {
  const {data} = await getChallenges();
  return data;
};
