import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';

import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {addGlobalBorderColor} from 'utils/theme';
import {Team} from 'types/Team';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Label from './Label';
import Codeword from './Codeword';
import PassphraseModal from './PassphraseModal';

import {ReactComponent as RightCaret} from 'icons/right-caret.svg';

const Wrapper = styled.div`
  display: block;
`;

const SelectBlock = styled(CopyTextSm)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid
    ${(props) => props.theme.components.teams?.selectBorderColor || '#3c3c3c'};

  ${addGlobalBorderColor()}
`;

const SelectValue = styled.div`
  display: block;
`;

const SelectIcon = styled.div`
  display: flex;
  height: 18px;
  fill: ${(props) => props.theme.components.teams?.caretColor};
`;

const SelectDropdown = styled.select`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const CodewordWrapper = styled.div`
  margin-top: 20px;
`;

const initialValues = {
  passphrase: '',
};

interface TeamSelectionProps {
  currentTeam?: Team | undefined;
  teams: Array<Team>;
  onTeamChange: (team: Team) => void;
  onJoinTeam: () => Promise<boolean>;
  label?: string;
  usePassphraseModal?: boolean;
}

const TeamSelection = (props: TeamSelectionProps) => {
  const {
    label,
    onTeamChange,
    onJoinTeam,
    currentTeam,
    teams,
    usePassphraseModal,
  } = props;
  const {translate} = useTranslations();
  const [isPassModalOpen, showPassphraseModal, hidePassphraseModal] =
    useBoolean(true);
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(
    currentTeam,
  );

  const validationSchema = yup.object().shape({});

  useEffect(() => {
    if (currentTeam) {
      setSelectedTeam(currentTeam);
    }
  }, [currentTeam]);

  const handleSubmit = useCallback(async () => {
    // Used by pass phrase modal

    // @ts-ignore
    document.activeElement?.blur();
    const joinedTeam = await onJoinTeam();
    if (joinedTeam) {
      hidePassphraseModal();
    }
  }, [onJoinTeam, hidePassphraseModal]);

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleTeamChange = (e: any) => {
    const selectedTeam = teams.find(
      (team: Team) => team.id.toString() === e.target.value.toString(),
    );

    if (selectedTeam) {
      setSelectedTeam(selectedTeam);
      onTeamChange(selectedTeam);
      formik.setFieldValue('passphrase', '');
    }

    showPassphraseModal();
  };

  const handlePassphraseChange = useCallback(
    (e: any) => {
      formik.handleChange(e);

      if (!selectedTeam) {
        return;
      }

      const team = {
        ...selectedTeam,
        passphrase: e.target.value,
      };

      onTeamChange(team);
    },
    [selectedTeam, onTeamChange, formik],
  );

  const handlePassphraseModalClose = useCallback(() => {
    setSelectedTeam(undefined);
    formik.setFieldValue('passphrase', '');
  }, [formik]);

  return (
    <Wrapper>
      <div>
        <Label>{label || translate('sdk.web.onboarding.teams.label')}</Label>
        {teams && (
          <SelectBlock>
            <SelectValue>
              {selectedTeam && selectedTeam.id
                ? selectedTeam.name
                : translate('sdk.web.onboarding.teams.select')}
            </SelectValue>
            <SelectIcon>
              <RightCaret />
            </SelectIcon>
            <SelectDropdown
              onChange={handleTeamChange}
              value={selectedTeam?.id || ''}>
              <option disabled={!!selectedTeam?.id}>
                {translate('sdk.web.onboarding.teams.select')}
              </option>
              {teams.map((team: Team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </SelectDropdown>
          </SelectBlock>
        )}
      </div>
      {selectedTeam?.passphrase_required && (
        <>
          {usePassphraseModal ? (
            <PassphraseModal
              team={selectedTeam}
              formik={formik}
              onChange={handlePassphraseChange}
              onClose={handlePassphraseModalClose}
              isVisible={isPassModalOpen}
            />
          ) : (
            <CodewordWrapper>
              <Codeword formik={formik} onChange={handlePassphraseChange} />
            </CodewordWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default TeamSelection;
