import {fetchAPiClient} from './api';
import {Wallet} from 'types/Wallet';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export const getWallets = async () => {
  const client = defaultApiClient;
  const url = '/user/wallets';
  return client.get<any>(url);
};

type GetWalletTransactionsParams = {
  page: number;
  limit?: number;
};
export const getWalletTransactions = async (
  params?: GetWalletTransactionsParams,
) => {
  const client = defaultApiClient;
  const url = '/user/wallets/transactions';
  const {data} = await client.get<any>(url, {
    params,
  });
  return data;
};

interface TransferCoinsParams {
  wallet_id: string;
  amount: number;
}

export const transferCoins = async (data: TransferCoinsParams) => {
  const client = defaultApiClient;
  const url = '/user/wallets/transactions';
  return client.post<any>(url, data);
};

interface GetCoinsTransferLinkParams {
  amount: number;
}

type GetCoinsTransferLinkResponse = {
  link: string;
};
export const getCoinsTransferLink = async (
  data: GetCoinsTransferLinkParams,
): Promise<GetCoinsTransferLinkResponse> => {
  const client = defaultApiClient;
  const url = '/user/wallets/send-coins';
  return client.post(url, data);
};

interface ReceiveCoinsParams {
  token: string;
}

export type ReceiveCoinsResponse = {
  amount: string;
  name: string;
};
export const receiveCoins = async (
  data: ReceiveCoinsParams,
): Promise<ReceiveCoinsResponse> => {
  const client = defaultApiClient;
  const url = '/user/wallets/receive-coins';
  return client.post(url, data);
};

interface AttachWalletParams {
  token: string;
  password: string;
}

export const attachWallet = async (
  data: AttachWalletParams,
): Promise<Wallet> => {
  const client = defaultApiClient;
  const url = '/user/wallets/link';
  return client.post<AttachWalletParams, Wallet>(url, data);
};

export const getBambooCoin = async (): Promise<Wallet> => {
  const client = defaultApiClient;
  const url = '/user/wallets/bamboo-coin';
  return client.get<never, Wallet>(url);
};
