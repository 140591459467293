import React from 'react';
import styled from 'styled-components';

import WidgetCard, {
  WidgetCardProps,
} from 'components/ClimateWidgets/Widget/WidgetCard';
import HeaderTextLg from 'components/Text/HeaderTextLg/HeaderTextLg';
import CopyText from 'components/Text/CopyText/CopyText';
// import HeaderText from "components/Text/HeaderText/HeaderText";

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: initial;
`;

const TextContent = styled.div`
  padding-right: 10px;
`;

const Title = styled(HeaderTextLg)<{mb: boolean}>`
  font-family: ${(props) => props.theme.font.secondary.name};
  color: ${(props) => props.theme.components.widgetTitle?.titleColor};
  display: block;
  margin-bottom: ${(props) => (props.mb ? '5px' : undefined)};
  word-break: break-word;
`;

const Image = styled.img`
  width: 85px;
  flex-shrink: 0;
`;

const Banner = styled.img`
  width: 100%;
  border-radius: 7px;
  margin-top: 10px;
`;

interface WidgetTileProps extends WidgetCardProps {
  title: string;
  subtitle?: string;
  image?: string;
  banner?: string;
  onClick?: (...data: any) => any;
}

const WidgetTile = (props: WidgetTileProps) => {
  const {title, subtitle, image, banner, onClick, ...rest} = props;

  return (
    <WidgetCard minHeight="20px" {...rest}>
      <Wrapper>
        <Content onClick={onClick}>
          <TextContent>
            <Title as="div" mb={!!subtitle}>
              {title}
            </Title>
            {subtitle && <CopyText as="div">{subtitle}</CopyText>}
          </TextContent>
          <Image src={image} alt="" />
        </Content>
        {banner && <Banner src={banner} alt="" onClick={onClick} />}
      </Wrapper>
    </WidgetCard>
  );
};

export default WidgetTile;
