import Bluebird from 'bluebird';
import omit from 'lodash/omit';

import {fetchAPiClient} from './api';
import {getChallengeData} from './challenges';
import {LeaderBoard, Team, TeamType} from 'types/Team';
import {Challenge, ChallengeLeaderBoard, UserRankings} from 'types/Challenge';
import {TRANSACTION_TYPES} from 'constants/transaction-type';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});
const activityApiClient = fetchAPiClient({subdomain: 'activity'});

export const getTeamTypeTeams = async ({
  challengeId,
  teamTypeId,
}: {
  challengeId: number;
  teamTypeId: number;
}) => {
  const client = activityApiClient;
  const url = `/statistics/challenges/${challengeId}/team-types/${teamTypeId}/teams?page=1&order_by=position&order=asc&limit=1000`;
  const {data} = await client.get<any>(url);
  return data;
};

export interface TeamLeaderBoard {
  teamTypeId: number;
  name: string;
  image: string;
  order?: number | null;
  current?: LeaderBoard;
  previousLeaderboards: Array<LeaderBoard>;
}

export const getTeamLeaderBoards = async (): Promise<
  Array<TeamLeaderBoard>
> => {
  const challenges = await getChallengeData();
  const challengeLeaderboards = challenges.map((challenge) =>
    getTeamsFromChallenge({challenge}),
  );

  const leaderboards: Record<number, TeamLeaderBoard> = {};

  challengeLeaderboards.forEach((challengeLeaderboard) => {
    if (!challengeLeaderboard) {
      return;
    }

    challengeLeaderboard.teamTypes.forEach((teamType) => {
      const leaderboard = {
        ...omit(challengeLeaderboard, ['teamTypes']),
        teams: teamType.teams,
      };

      if (!leaderboards[teamType.id]) {
        leaderboards[teamType.id] = {
          teamTypeId: teamType.id,
          name: teamType.name,
          image: teamType.image,
          order: teamType.order,
          previousLeaderboards: [],
        };
      }

      if (challengeLeaderboard.current) {
        leaderboards[teamType.id].current = leaderboard;
      } else {
        leaderboards[teamType.id].previousLeaderboards.push(leaderboard);
      }
    });
  });

  return Object.values(leaderboards)
    .filter(({current}) => current?.teams.length)
    .sort((a, b) =>
      typeof a.order === 'number' && typeof b.order === 'number'
        ? a.order - b.order
        : 0,
    );
};

const getTeamsFromChallenge = ({
  challenge,
}: {
  challenge: Challenge;
}): ChallengeLeaderBoard | undefined => {
  if (!challenge.leaderboards) {
    return undefined;
  }

  const allTeamTypes = challenge.leaderboards.team_types;
  if (!allTeamTypes.length) {
    return undefined;
  }

  const challengeData = {
    name: challenge.name,
    end_date: challenge.end_date,
    total_co2_avoided: challenge.organisation.co2_avoided,
    total_distance: challenge.organisation.meters,
    challengeId: challenge.id,
    challengeType: challenge.type,
    current: challenge.current,
    teams: [],
  };

  const activities = Object.values(TRANSACTION_TYPES).map(
    ({transactionTypes, key}) => {
      const value = challenge.organisation.summary
        .filter(({transaction_type_id}) =>
          transactionTypes.includes(transaction_type_id),
        )
        .reduce<number>((total, {quantity}) => total + quantity, 0);

      return {
        key,
        value,
      };
    },
  );

  return {
    ...challengeData,
    activities,
    current: challenge.current,
    end_date: challenge.end_date,
    teamTypes: allTeamTypes,
    userRanking: challenge.leaderboards.users.me?.position,
  };
};

export const getAllTeams = async (): Promise<Array<Team>> => {
  const client = defaultApiClient;
  const url = '/team-types/teams';
  const {data: allTeamTypes} = await client.get<any>(url);
  if (!allTeamTypes.length) {
    return [];
  }

  return allTeamTypes[0].teams.sort((a: Team, b: Team) => a.order - b.order);
};

export const getAllTeamTypes = async (): Promise<Array<TeamType>> => {
  const client = defaultApiClient;
  const url = '/team-types/teams';
  const {data: allTeamTypes} = await client.get<TeamType[]>(url);

  return allTeamTypes.map(({teams, ...rest}) => ({
    ...rest,
    teams: teams.sort((a: Team, b: Team) => a.order - b.order),
  }));
};

interface JoinTeam {
  teamId: number;
  passphrase?: string;
}

export const joinTeam = async ({teamId, passphrase}: JoinTeam) => {
  const client = defaultApiClient;
  const url = `/team/${teamId}/join`;
  return client.post<any>(url, {passphrase});
};

export const leaveTeam = async (teamId: number) => {
  const client = defaultApiClient;
  const url = `/team/${teamId}/leave`;
  return client.post<any>(url);
};

export type GroupLeaderboards = Array<LeaderBoard>;

export const getGroupLeaderboards = async (): Promise<GroupLeaderboards> => {
  const {data: groups} = await getGroups();

  const leaderBoards = (await Bluebird.map(groups, async (group: any) => {
    const currentChallenge = group.challenges.find(
      (challenge: any) => challenge.current,
    );
    if (!currentChallenge) {
      return;
    }

    const currentChallengeLeaderboard = await getChallengeLeaderboard({
      group,
      challenge: currentChallenge,
    });

    const previousChallenges = group.challenges
      .filter((challenge: any) => !challenge.current)
      .slice(0, 4);
    const previousLeaderboards = await Bluebird.map(
      previousChallenges,
      (challenge: any) => getChallengeLeaderboard({group, challenge}),
    );

    return {
      ...currentChallengeLeaderboard,
      previousLeaderboards,
    };
  }).filter((challenge: any) => challenge)) as Array<LeaderBoard>;

  return leaderBoards;
};

type GetChallengeLeaderboard = {
  group: any;
  challenge: any;
};
const getChallengeLeaderboard = async ({
  group,
  challenge,
}: GetChallengeLeaderboard) => {
  const teams = (
    await getGroupChallenge({
      groupId: group.id,
      challengeId: challenge.id,
    })
  ).map((team, index) =>
    // @ts-ignore
    ({position: index + 1, ...team, name: team.name || team.display_name}),
  );

  return {
    name: group.name as string,
    current: challenge.current,
    end_date: challenge.end_date,
    challengeType: challenge.type,
    teams,
  };
};

const getGroups = () => {
  const client = activityApiClient;
  const url = '/statistics/groups';
  return client.get<any>(url);
};

interface GetGroupChallenge {
  groupId: number;
  challengeId: number;
}

export const getGroupChallenge = async ({
  groupId,
  challengeId,
}: GetGroupChallenge): Promise<Array<Team>> => {
  const client = activityApiClient;
  const url = `/statistics/groups/${groupId}/challenges/${challengeId}/organisations?limit=100&page=1`;
  const {data: challenge} = await client.get<any>(url);
  return challenge;
};

export const getUserRankings = async (): Promise<UserRankings> => {
  const client = activityApiClient;
  const url = 'statistics/challenges/current/users/me';
  return client.get<never, UserRankings>(url);
};
