import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import config from '../config';
import {CACHE_KEYS} from 'constants/cache-keys';
import {MAX_COMPARISON} from '../constants';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTokenTranslation} from 'hooks/use-token-translation';
import useRelativeText from 'hooks/utils/use-relative-text';
import {useFetcher} from 'hooks/use-fetcher';
import useElementTranslation from '../hooks/use-element-translation';
import {getPreviousResults} from 'services/metering';
import {getMonthTranslation} from 'utils/date';
import {formatNumber} from 'utils/numbers';
import {PreviousResultsData} from 'types/Metering';
import {ElementScreenProps} from '../types';

import Loader from 'components/Loader/Loader';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyText from 'components/Text/CopyText/CopyText';
import TextXs from 'components/Text/TextXs/TextXs';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import {ReactComponent as TokenIcon} from 'components/ClimateWidgets/Metering/icons/token.svg';
import {ReactComponent as ComparisonIcon} from 'components/ClimateWidgets/Metering/icons/comparison.svg';

const Wrapper = styled.div`
  padding: 0 20px;

  .e-prev {
    &__year {
      margin-bottom: 30px;
      padding: 0 30px;
    }

    &__month {
      padding: 15px 10px 0;
      margin-bottom: 15px;
      background-color: ${(props) =>
        props.theme.components.metering?.prevResultsMonthBgColor || '#c0e4e8'};
      border-radius: 10px;

      &--reset {
        padding-bottom: 15px;
      }
    }

    &__month-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};
      margin-bottom: 15px;
    }

    &__month-budget {
      color: ${(props) => props.theme.colors.primary};
    }

    &__summary-list {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: left;
    }

    &__summary {
      width: 50%;
      padding-right: 20px;
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      &-icon {
        width: 30px;
        margin-right: 7px;
        flex-shrink: 0;

        img {
          width: 100%;
        }
      }

      &-value {
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;

        &--negative {
          color: #ff0000;
        }
      }
    }

    &__reset-hr {
      width: 100%;
      height: 1px;
      background-color: ${(props) => props.theme.colors.primary};
      margin-top: 4px;
    }

    &__text-xs {
      display: inline-block;
      line-height: 16px;
    }
  }
`;

const PreviousResults = (props: ElementScreenProps) => {
  const {element, elementData} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const translate = useElementTranslation({element});
  const {getTokenText} = useTokenTranslation();
  const getRelativeText = useRelativeText({translate});

  const meteringConfig = organizationConfig.theme.components.metering;

  const {
    data: previousResults,
    isLoading,
  }: {
    data: PreviousResultsData;
    isLoading: boolean;
  } = useFetcher({
    key: CACHE_KEYS.METERING[element].PREVIOUS_RESULTS,
    fetcher: getPreviousResults,
    params: {
      type: element,
    },
  });

  const stats = useMemo(() => {
    if (!previousResults) {
      return;
    }

    return {
      year: {
        summary: (
          <span>
            {translate(
              previousResults.year.total_savings < 0
                ? 'sdk.web.meter.prev.budget.above'
                : 'sdk.web.meter.prev.saved',
              [
                {
                  key: '{year}',
                  value: new Date().getFullYear(),
                },
                {
                  key: '{nr}',
                  value: formatNumber({
                    number: Math.abs(previousResults.year.total_savings),
                  }),
                },
              ],
            )}{' '}
            {translate('sdk.web.meter.prev.token', [
              {
                key: '{nr}',
                value: formatNumber({
                  number: previousResults.year.recoins_earned,
                }),
              },
              {
                key: '{tokens}',
                value: getTokenText(previousResults.year.recoins_earned),
              },
            ])}{' '}
            {config.hideComparison
              ? ''
              : getRelativeText({
                  key: 'sdk.web.meter.prev',
                  percent: previousResults.year.relative_performance,
                  max: MAX_COMPARISON,
                })}
          </span>
        ),
      },
      months: previousResults.months.map((month, index) => ({
        key: month.month,
        title: `${getMonthTranslation({
          translate,
          date: new Date(month.month),
        })} ${new Date(month.month).getFullYear()}`,
        budget: translate('sdk.web.meter.prev.i.budget', {
          key: '{nr}',
          value: formatNumber({number: month.goal, dp: 0}),
        }),
        summary: [
          {
            key: 'used',
            value: formatNumber({number: month.consumption, dp: 0}),
            label: translate('sdk.web.meter.prev.i.used'),
            negative: false,
            iconImage: true,
            Icon: elementData.resultsIcon,
          },
          {
            key: 'budget',
            value: formatNumber({
              number: Math.abs(month.savings),
              dp: 0,
            }),
            label: translate(
              month.savings < 0
                ? 'sdk.web.meter.prev.i.budget.above'
                : 'sdk.web.meter.prev.i.saved',
            ),
            negative: month.savings < 0,
            iconImage: true,
            Icon: elementData.icon,
          },
          {
            key: 'tokens',
            value: formatNumber({number: month.recoins_earned}),
            label: translate('sdk.web.meter.prev.i.tokens', {
              key: '{tokens}',
              value: getTokenText(month.recoins_earned),
            }),
            negative: false,
            Icon: meteringConfig?.icons?.tokenIcon || TokenIcon,
          },
          ...(config.hideComparison
            ? []
            : [
                {
                  key: 'comparison',
                  value: `${formatNumber({
                    number: Math.min(
                      Math.abs(month.relative_performance),
                      MAX_COMPARISON,
                    ),
                    dp: 0,
                  })}%`,
                  label: getRelativeText({
                    key: 'sdk.web.meter.prev.i',
                    percent: month.relative_performance,
                  }),
                  negative: month.relative_performance > 0,
                  Icon: ComparisonIcon,
                },
              ]),
        ],
        showMeterReset:
          index !== previousResults.months.length - 1 &&
          previousResults.months[index + 1].user_meter_id !==
            month.user_meter_id,
      })),
    };
  }, [
    elementData,
    previousResults,
    translate,
    getTokenText,
    getRelativeText,
    meteringConfig,
  ]);

  if (!stats && !isLoading) {
    return null;
  }

  return (
    <Wrapper>
      <Title>{translate('sdk.web.meter.prev.title')}</Title>
      {isLoading ? (
        <Loader color="#222" />
      ) : (
        stats && (
          <>
            <div className="e-prev__year">
              <CopyText>{stats.year.summary}</CopyText>
            </div>
            <div>
              {stats.months.map((month) => (
                <div key={month.key}>
                  <div className="e-prev__month">
                    <CopyTextSm as="div">
                      <div className="e-prev__month-title">
                        <div>{month.title}</div>
                        <div className="e-prev__month-budget">
                          {month.budget}
                        </div>
                      </div>
                    </CopyTextSm>
                    <div className="e-prev__summary-list">
                      {month.summary.map((summaryData) => (
                        <div className="e-prev__summary" key={summaryData.key}>
                          <div className="e-prev__summary-icon">
                            {summaryData.iconImage ? (
                              <img src={summaryData.Icon} alt="" />
                            ) : (
                              <summaryData.Icon />
                            )}
                          </div>
                          <div className="e-prev__summary-text">
                            <div
                              className={classnames('e-prev__summary-value', {
                                'e-prev__summary-value--negative':
                                  summaryData.negative,
                              })}>
                              {summaryData.value}
                            </div>
                            <TextXs className="e-prev__text-xs">
                              {summaryData.label}
                            </TextXs>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {month.showMeterReset && (
                    <div className="e-prev__month e-prev__month--reset">
                      <CopyTextSm as="div">
                        <b>{translate('sdk.web.meter.prev.reset')}</b>
                      </CopyTextSm>
                      <div className="e-prev__reset-hr" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )
      )}
    </Wrapper>
  );
};

export default PreviousResults;
