import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';

import config from '../../config';
import {SettingsContext} from 'contexts/SettingsContext';
import {MAX_COMPARISON} from '../../constants';
import {useFetcher} from 'hooks/use-fetcher';
import {useTokenTranslation} from 'hooks/use-token-translation';
import useRelativeText from 'hooks/utils/use-relative-text';
import useElementTranslation from '../../hooks/use-element-translation';
import {getPreviousResults} from 'services/metering';
import {getMonthTranslation} from 'utils/date';
import {formatNumber} from 'utils/numbers';
import {getBadge} from './utils/badge';
import {PreviousResultsData} from 'types/Metering';
import {ConsumptionScreenProps} from './types';
import {ELEMENT_SCREENS} from '../../types';

import Loader from 'components/Loader/Loader';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyText from 'components/Text/CopyText/CopyText';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px 40px;

  .e-results {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

const MonthResults = (props: ConsumptionScreenProps) => {
  const {element, onComplete} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const translate = useElementTranslation({element});
  const {getTokenText} = useTokenTranslation();
  const getRelativeText = useRelativeText({translate});

  const badgesIconConfig =
    organizationConfig.theme.components.metering?.icons?.badges;

  const {
    data: previousResults,
    isLoading,
  }: {
    data: PreviousResultsData;
    isLoading: boolean;
  } = useFetcher({
    fetcher: getPreviousResults,
    params: {
      type: element,
    },
  });

  const {
    success,
    title,
    image,
    saved,
    percentageSaved,
    usage,
    comparison,
    cta,
    prev,
  } = useMemo(() => {
    if (isLoading || !previousResults || !previousResults.months[0]) {
      return {saved: '', usage: ''};
    }

    const previousMonthResult = previousResults.months[0];
    const success = previousMonthResult.savings > 0;
    const month = getMonthTranslation({
      translate,
      date: new Date(previousMonthResult.month),
    });

    const percentageSaved = success
      ? Math.ceil(
          (previousMonthResult.savings / previousMonthResult.goal) * 100,
        )
      : 0;
    const badge = getBadge({
      percentageSaved,
      element,
      translate,
      badgesIconConfig,
    });

    return {
      success,
      title: translate(
        success
          ? 'sdk.web.meter.results.success.title'
          : 'sdk.web.meter.results.failure.title',
      ),
      image: badge.image,
      saved: success ? translate('sdk.web.meter.results.saved.v2') : '',
      percentageSaved: formatNumber({number: percentageSaved}),
      usage: success
        ? translate('sdk.web.meter.results.success.usage.v2', [
            {
              key: '{month}',
              value: month,
            },
            {
              key: '{savings}',
              value: formatNumber({
                number: previousMonthResult.savings,
              }),
            },
            {
              key: '{nr}',
              value: formatNumber({number: previousMonthResult.recoins_earned}),
            },
            {
              key: '{tokens}',
              value: getTokenText(previousMonthResult.recoins_earned),
            },
          ])
        : translate('sdk.web.meter.results.failure.usage.v2', [
            {
              key: '{month}',
              value: month,
            },
            {
              key: '{amount}',
              value: formatNumber({
                number:
                  previousMonthResult.consumption - previousMonthResult.goal,
              }),
            },
          ]),
      comparison: config.hideComparison
        ? ''
        : getRelativeText({
            key: 'sdk.web.meter.results.comparison',
            percent: previousMonthResult.relative_performance,
            max: MAX_COMPARISON,
          }),
      cta: translate('sdk.web.meter.results.cta'),
      prev: translate('sdk.web.meter.results.prev'),
    };
  }, [
    element,
    previousResults,
    isLoading,
    translate,
    getTokenText,
    getRelativeText,
    badgesIconConfig,
  ]);

  return (
    <Wrapper>
      {isLoading ? (
        <Loader color="#222" />
      ) : (
        <>
          <div className="e-results__content">
            <Title>{title}</Title>
            <img className="e-results__img" src={image} alt="banner" />
            {success && (
              <div className="mb-base">
                <HeaderText as="div">{percentageSaved}%</HeaderText>
                <CopyText as="div" dangerouslySetInnerHTML={{__html: saved}} />
              </div>
            )}
            <CopyText
              as="div"
              className="mb-base"
              dangerouslySetInnerHTML={{__html: usage}}
            />
            {comparison && (
              <CopyText as="div" className="mb-base">
                {comparison}
              </CopyText>
            )}
          </div>
          <div>
            <div className="mb-base">
              <Button onClick={() => onComplete()}>{cta}</Button>
            </div>
            <CtaLink
              onClick={() =>
                onComplete({
                  nextElementScreen: ELEMENT_SCREENS.PREVIOUS_RESULTS,
                })
              }>
              {prev}
            </CtaLink>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default MonthResults;
