import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {TOKEN_EXPIRED} from 'constants/api-errors';
import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {useTranslations} from 'hooks/use-translations';
import {useNativeListener} from 'hooks/use-native-listener';
import {useBoolean} from 'hooks/utils/use-boolean';
import {logout} from 'services/account';
import {sendMessageToNativeApp} from 'services/native-api';

import DialogBox from 'components/DialogBox/DialogBox';
import LogoutError from 'components/Account/LogoutError';

const LOGOUT_TIMEOUT = 30000;
const NEW_TOKEN_TIMEOUT = 18000;
const MAX_NEW_TOKEN_RETRIES = 2;

interface LogoutDialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutDialogBox = (props: LogoutDialogBoxProps) => {
  const {isOpen, onClose} = props;
  const {push} = useHistory();
  const {translate} = useTranslations();
  const hasNewToken = useRef(false);

  const [isTokenRequested, setIsTokenRequested] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isErrorOpen, openError] = useBoolean(false);

  const requestForNewToken = useCallback(() => {
    const message = {
      type: NATIVE_MESSAGES.REQUEST_NEW_TOKEN,
    };
    sendMessageToNativeApp({message});
    setIsTokenRequested(true);
  }, []);

  useEffect(
    () => {
      if (!isTokenRequested) {
        return;
      }

      let retryCounter = 0;
      const timer = setInterval(() => {
        retryCounter++;

        if (retryCounter >= MAX_NEW_TOKEN_RETRIES) {
          clearInterval(timer);
          openError();
          setIsTokenRequested(false);
          return;
        }

        if (hasNewToken.current) {
          clearInterval(timer);
          return;
        } else {
          requestForNewToken();
        }
      }, NEW_TOKEN_TIMEOUT);

      return () => {
        clearInterval(timer);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTokenRequested],
  );

  const handleLogoutConfirmation = useCallback(
    async (shouldLogout: boolean) => {
      if (!shouldLogout) {
        onClose();
        return;
      }

      setIsLoggingOut(true);

      try {
        await logout({timeout: LOGOUT_TIMEOUT});
        requestForNewToken();
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        if (errorKey === TOKEN_EXPIRED) {
          requestForNewToken();
          return;
        }

        setIsLoggingOut(false);
      }
    },
    [requestForNewToken, onClose],
  );

  const handleNewToken = useCallback(() => {
    hasNewToken.current = true;
    push(routes.SETTINGS.href);
  }, [push]);

  useNativeListener({
    event: NATIVE_EVENTS.NEW_TOKEN,
    callback: handleNewToken,
  });

  return (
    <>
      {isOpen && (
        <DialogBox
          title={translate('sdk.web.account.logout.title')}
          promptMessage={translate('sdk.web.account.logout.prompt')}
          onConfirmation={handleLogoutConfirmation}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isLoggingOut}
        />
      )}
      {isErrorOpen && (
        <LogoutError
          isRetrying={isTokenRequested}
          onRetry={requestForNewToken}
        />
      )}
    </>
  );
};

export default LogoutDialogBox;
