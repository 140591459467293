import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

import routes from 'config/routes';
import talerConfig from 'config/taler';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {useUserProfile} from 'hooks/use-user-profile';
import {useBoolean} from 'hooks/utils/use-boolean';
import {getTransactions} from 'services/taler';
import {TalerTransaction, TRANSFER_TYPE} from 'types/Taler';
import {getWallets} from 'services/wallets';
import {Wallets} from 'types/Wallet';
import {formatNumber} from 'utils/numbers';
import {formatDate} from 'utils/date';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';
import PullToRefresh from 'components/PullToRefresh';
import Loader from 'components/Loader/Loader';
import PromptModal from 'components/PromptModal/PromptModal';
import AvailableCoins from 'pages/Dashboard/WalletsPage/AvailableCoins';
import TransferModal from './TransferModal';
import TransactionModal from './TransactionModal';

import iosIcon from 'icons/ios.svg';
import playStoreIcon from 'icons/play-store.svg';

const downloadLinks = [
  {
    platform: 'ios',
    icon: iosIcon,
    url: talerConfig.iosUrl,
  },
  {
    platform: 'android',
    icon: playStoreIcon,
    url: talerConfig.playStoreUrl,
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .taler-balance {
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1;
    margin-bottom: 30px;
  }

  .taler-trx {
    // height: 100%;
    padding-top: 40px;

    $__container: {
      overflow: auto;
      padding-top: 50px;
      height: 100%;
    }

    &__scroll-container {
      overflow: auto;
      height: 100%;
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    &__th {
      padding-bottom: 10px;
      border-bottom: 2px solid ${(props) => props.theme.colors.primary};

      &--day {
        width: 100px;
      }

      &--activity {
        width: calc((100% - 150px) / 2);
      }

      &--coins {
        width: 70px;
        text-align: right;
      }
    }

    &__td-coins {
      color: ${(props) =>
        props.theme.components.wallets?.textColor ||
        props.theme.colors.primary};
      text-align: right;

      &--danger {
        color: #d20000;
      }
    }

    &__coin-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__action {
      width: 20px;
      color: #000;

      svg {
        width: 100%;
      }
    }

    &.taler-trx {
      td {
        padding: 14px 0;
        border-bottom: 1px solid ${(props) => props.theme.colors.primary}5D;
      }
    }
  }

  .taler-share {
    display: flex;
    justify-content: center;

    &__link {
      margin: 0 10px;
      width: 120px;
      max-width: calc(50% - 20px);
    }

    &__icon {
      width: 100%;
    }
  }
`;

const GNUTalerWallet = () => {
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});
  const {tokenAbbreviation} = useTokenTranslation();
  const {userProfile} = useUserProfile();

  const [transferType, setTransferType] = useState<TRANSFER_TYPE | undefined>();
  const [activeTransactionId, setActiveTransactionId] = useState<
    number | null
  >();
  const [isAboutInfoOpen, showAboutInfo, closeAboutInfo] = useBoolean(false);

  const {data: wallets, fetchData: refetchWallets} = useFetcher<Wallets>({
    fetcher: getWallets,
    key: CACHE_KEYS.WALLETS,
  });

  const {
    data: transactionsData,
    pagination,
    fetchData: fetchMoreTransactions,
    addDataItem: addNewTransactionData,
  } = useFetcher<TalerTransaction[]>({
    fetcher: getTransactions,
    key: CACHE_KEYS.TALER_TRANSACTIONS,
    paginate: true,
    limit: 10,
    initialValue: [],
  });

  const handleRefresh = useCallback(async () => {
    Promise.all([
      fetchMoreTransactions(null, {resetPage: true}),
      refetchWallets(),
    ]);
  }, [fetchMoreTransactions, refetchWallets]);

  useEffect(
    () => {
      const interval = setInterval(() => {
        handleRefresh();
      }, 4000);

      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const aboutInfo = useMemo(
    () => userProfile.organisation.gnu_taler_text,
    [userProfile],
  );

  const transactions = useMemo(
    () =>
      transactionsData.map((transaction) => {
        const amount = parseFloat(transaction.amount);
        const outgoing = transaction.type === TRANSFER_TYPE.SEND;

        return {
          id: transaction.id,
          type:
            translate(`sdk.web.gnu.taler.type.${transaction.type}`) ||
            transaction.type,
          status:
            translate(`sdk.web.gnu.taler.status.${transaction.status}`) ||
            transaction.status,
          link: transaction.taler_uri,
          qrCodeURL: transaction.qr_code_url,
          day: formatDate({date: transaction.created_at}),
          coins: formatNumber({
            number: Math.abs(amount),
            skipNormalization: true,
            dp: 2,
          }),
          coinsModifier: outgoing ? '-' : '+',
          classModifier: outgoing ? 'danger' : '',
        };
      }),
    [transactionsData, translate],
  );

  const activeTransaction = useMemo(
    () =>
      transactionsData.find(
        (transaction) => transaction.id === activeTransactionId,
      ),
    [transactionsData, activeTransactionId],
  );

  const handleTransferClose = useCallback(() => {
    setTransferType(undefined);
  }, []);

  const handleTransferComplete = useCallback(
    (transaction: TalerTransaction) => {
      addNewTransactionData({data: transaction});
      setActiveTransactionId(transaction.id);
      setTimeout(() => {
        handleRefresh();
        handleTransferClose();
      }, 50);
    },
    [addNewTransactionData, handleTransferClose, handleRefresh],
  );

  return (
    <InfoPage
      title={translate('sdk.web.gnu.taler.title')}
      onClose={handleGoBack}
      onInfo={showAboutInfo}>
      <Wrapper>
        <div>
          {wallets?.default && (
            <div className="taler-balance">
              <AvailableCoins coinBalance={wallets.default.coin_balance} />
            </div>
          )}
          <div className="mb-base">
            <Button onClick={() => setTransferType(TRANSFER_TYPE.SEND)}>
              {translate('sdk.web.gnu.taler.send')}
            </Button>
          </div>
          <Button onClick={() => setTransferType(TRANSFER_TYPE.RECEIVE)}>
            {translate('sdk.web.gnu.taler.receive')}
          </Button>
          {transferType && (
            <TransferModal
              isVisible={!!transferType}
              transferType={transferType}
              coinBalance={Math.floor(wallets?.default?.coin_balance || 0)}
              onComplete={handleTransferComplete}
              onClose={handleTransferClose}
            />
          )}
        </div>
        {transactions.length > 0 && (
          <div className="taler-trx">
            <div className="taler-trx__container" id="scrollContainer">
              <PullToRefresh loaderColor="#222" onRefresh={handleRefresh}>
                <div className="taler-trx__scroll-container">
                  <InfiniteScroll
                    dataLength={transactions.length}
                    next={fetchMoreTransactions}
                    hasMore={pagination.hasMore}
                    loader={<Loader sm color="#222" />}
                    scrollableTarget="scrollContainer">
                    <div className="taler-trx__content">
                      <div className="taler-trx__month-table">
                        <table className="taler-trx__table">
                          <thead>
                            <tr>
                              <th className="taler-trx__th taler-trx__th--day">
                                {translate('sdk.web.gnu.taler.trx.day')}
                              </th>
                              <th className="taler-trx__th taler-trx__th--activity">
                                {translate('sdk.web.gnu.taler.trx.activity')}
                              </th>
                              <th className="taler-trx__th taler-trx__th--activity">
                                {translate('sdk.web.gnu.taler.trx.status')}
                              </th>
                              <th className="taler-trx__th taler-trx__th--coins">
                                {tokenAbbreviation}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((transaction) => (
                              <tr
                                key={transaction.id}
                                onClick={() =>
                                  setActiveTransactionId(transaction.id)
                                }>
                                <td>{transaction.day}</td>
                                <td>{transaction.type}</td>
                                <td>{transaction.status}</td>
                                <td
                                  className={`taler-trx__td-coins taler-trx__td-coins--${transaction.classModifier}`}>
                                  <div className="taler-trx__coin-actions">
                                    <div>
                                      {transaction.coinsModifier}
                                      {transaction.coins}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </InfiniteScroll>
                </div>
              </PullToRefresh>
            </div>
          </div>
        )}
        <div className="taler-share">
          {downloadLinks.map((link) => (
            <a
              key={link.platform}
              className="taler-share__link"
              href={link.url}
              target="_blank"
              rel="noreferrer noopener">
              <img
                className="taler-share__icon"
                src={link.icon}
                alt={link.platform}
              />
            </a>
          ))}
        </div>
      </Wrapper>
      {activeTransaction && (
        <TransactionModal
          transaction={activeTransaction}
          onClose={() => setActiveTransactionId(null)}
        />
      )}
      <PromptModal
        title={translate('sdk.web.gnu.taler.title')}
        isVisible={isAboutInfoOpen}
        textContent={aboutInfo}
        btnText={translate('sdk.web.dialog.box.ok')}
        onClose={closeAboutInfo}
        onBtnClick={closeAboutInfo}
      />
    </InfoPage>
  );
};

export default GNUTalerWallet;
