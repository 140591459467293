import omit from 'lodash/omit';

import {uploadImageData} from 'services/images';
import {METERING_ELEMENTS} from 'types/Metering';
import {fetchAPiClient} from './api';
import {ElementTranslate} from 'components/ClimateWidgets/Metering/hooks/use-element-translation';
import {SETTINGS_LIMIT} from 'components/ClimateWidgets/Metering/constants';
import {toBoolean} from 'utils/strings';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

type EnergyTypeParams = {
  type: METERING_ELEMENTS;
};

type EnergyProvidersParams = {
  type: METERING_ELEMENTS;
  search?: string;
  include?: number;
};

export const getEnergyProviders = async ({
  type,
  search,
  include,
}: EnergyProvidersParams) => {
  const client = defaultApiClient;
  const url = '/metering/energy-providers';
  const response = await client.get<any>(url, {
    params: {
      type,
      search,
      include,
    },
  });

  return response.data;
};

const parseMeterResponse = (response: any) => ({
  ...omit(response, 'settings'),
  // @ts-ignore
  ...response?.settings,
});

export const getUserMeter = async ({type}: EnergyTypeParams) => {
  const client = defaultApiClient;
  const url = '/metering/user-meter';
  const response = await client.get<any>(url, {
    params: {
      type,
    },
  });

  return parseMeterResponse(response);
};

export const getHouseholdSettings = async () => {
  const client = defaultApiClient;
  const url = '/user-household-settings';
  const response = await client.get<any>(url);
  if (!response.data) {
    throw new Error('No data');
  }

  Object.keys(response.data).forEach((key) => {
    if (response.data[key] === null) {
      response.data[key] = '';
    }
  });

  return {
    user_household_settings: response.data,
  };
};

export const getUserStats = async ({type}: EnergyTypeParams) => {
  const client = defaultApiClient;
  const url = '/metering/user-stats';
  return client.get<any>(url, {
    params: {
      type,
    },
  });
};

export const getAllUserStats = async () => {
  const client = defaultApiClient;
  const url = '/metering/all-user-stats';
  return client.get<any>(url);
};

export const getHouseHoldSettings = async () => {
  const client = defaultApiClient;
  const url = '/user-household-settings';
  const response = await client.get<any>(url);

  return response.data || {};
};
export const connect = async (data: any) => {
  const client = defaultApiClient;
  const url = '/metering/connect';
  const response = await client.post<any>(url, data);

  return parseMeterResponse(response);
};

export const disconnect = async ({type}: EnergyTypeParams) => {
  const client = defaultApiClient;
  const url = '/metering/disconnect';
  return client.post<any>(url, {driver: 'manual', type});
};

export const reset = async ({type}: EnergyTypeParams) => {
  const client = defaultApiClient;
  const url = '/metering/reset';
  return client.post<any>(url, {driver: 'manual', type});
};

export const getPreviousResults = async ({type}: EnergyTypeParams) => {
  const client = defaultApiClient;
  const url = '/metering/previous-results';
  return client.get<any>(url, {
    params: {
      type,
    },
  });
};

interface ManualMeasurementData {
  value: number;
  image?: string;
  type: METERING_ELEMENTS;
  force?: boolean;
}

export type ManualMeasurement = {
  type: string;
  previous_value: number;
  value: number;
  monthly_value: number;
  last_measured_at: string;
  is_new_month: boolean;
};

interface AddManualMeasurementParams {
  data: ManualMeasurementData;
  update: boolean;
}

export const addManualMeasurement = async ({
  data,
  update,
}: AddManualMeasurementParams): Promise<ManualMeasurement> => {
  const client = defaultApiClient;
  const url = '/metering/manual/measurement';

  const verb = update ? client.put : client.post;
  return verb(url, data);
};

export type MeteringImageData = {
  url: string;
  image: string;
  suggested_reading?: number;
};

type UploadMeteringImageParams = {
  image: string;
  force?: boolean;
};
export const uploadMeteringImage = async (
  data: UploadMeteringImageParams,
): Promise<MeteringImageData> => {
  const uploadedImage = await uploadImageData<MeteringImageData>({
    ...data,
    type: 'image',
    category: 'manual_metering',
    url: '/metering/manual/upload-image',
  });

  return {
    ...uploadedImage,
    image: data.image,
  };
};

interface GetMeteringSettingsOptionsParams {
  translate: ElementTranslate;
  energyProviders: Array<{
    id: number;
    label: string;
    value: string;
  }>;
  onAppliancesInfoClick: () => any;
  onEnergyProviderSearch: (params: any) => any;
}

export const getMeteringSettingsOptions = ({
  translate,
  onAppliancesInfoClick,
  onEnergyProviderSearch,
  energyProviders,
}: GetMeteringSettingsOptionsParams) => {
  const yesNoOptions = [
    {
      id: 1,
      value: true,
      label: translate('sdk.web.meter.settings.yes'),
    },
    {
      id: 2,
      value: false,
      label: translate('sdk.web.meter.settings.no'),
    },
  ];

  const energyTypeOptions = [
    {
      id: 2,
      value: 'mix',
      label: translate('sdk.web.meter.settings.mix'),
    },
    {
      id: 1,
      value: 'renewable',
      label: translate('sdk.web.meter.settings.renewable'),
    },
  ];

  const buildingTypeOptions = [
    {
      id: 1,
      value: 'passive_house',
      label: translate('sdk.web.meter.settings.bt.passive_house'),
    },
    {
      id: 2,
      value: 'new_enev',
      label: translate('sdk.web.meter.settings.bt.new_enev'),
    },
    {
      id: 3,
      value: 'new_conv',
      label: translate('sdk.web.meter.settings.bt.new_conv'),
    },
    {
      id: 4,
      value: 'refurbished',
      label: translate('sdk.web.meter.settings.bt.refurbished'),
    },
    {
      id: 5,
      value: 'old',
      label: translate('sdk.web.meter.settings.bt.old'),
    },
  ];

  const heatPumpTypeOptions = [
    {
      id: 1,
      value: 'air',
      label: translate('sdk.web.meter.settings.hp.air'),
    },
    {
      id: 2,
      value: 'air_water',
      label: translate('sdk.web.meter.settings.hp.air_water'),
    },
    {
      id: 3,
      value: 'brine_collector',
      label: translate('sdk.web.meter.settings.hp.brine_collector'),
    },
    {
      id: 4,
      value: 'brine_probe',
      label: translate('sdk.web.meter.settings.hp.brine_probe'),
    },
    {
      id: 5,
      value: 'water',
      label: translate('sdk.web.meter.settings.hp.water'),
    },
  ];

  return [
    {
      key: 'user_household_settings.people',
      validationKey: 'user_household_settings.fields.people',
      type: 'number',
      label: {
        key: 'sdk.web.meter.settings.household',
      },
      inputOptions: {
        min: `${SETTINGS_LIMIT.HOUSEHOLD_PEOPLE.MIN}`,
        max: `${SETTINGS_LIMIT.HOUSEHOLD_PEOPLE.MAX}`,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'user_household_settings.size',
      validationKey: 'user_household_settings.fields.size',
      type: 'number',
      label: {
        key: 'sdk.web.meter.settings.apt.size',
      },
      inputOptions: {
        min: `${SETTINGS_LIMIT.SIZE_OF_APARTMENT.MIN}`,
        max: `${SETTINGS_LIMIT.SIZE_OF_APARTMENT.MAX}`,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'user_household_settings.appliances_count',
      validationKey: 'user_household_settings.fields.appliances_count',
      type: 'number',
      label: {
        key: 'sdk.web.meter.settings.appliances',
        onInfoClick: onAppliancesInfoClick,
      },
      inputOptions: {
        min: `${SETTINGS_LIMIT.APPLIANCES_COUNT.MIN}`,
        max: `${SETTINGS_LIMIT.APPLIANCES_COUNT.MAX}`,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'user_household_settings.electric_water_heating',
      validationKey: 'user_household_settings.fields.electric_water_heating',
      type: 'radio',
      label: {
        key: 'sdk.web.meter.settings.heating',
      },
      inputOptions: null,
      radioOptions: {
        list: yesNoOptions,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'user_household_settings.gas_water_heating',
      validationKey: 'user_household_settings.fields.gas_water_heating',
      type: 'radio',
      label: {
        key: 'sdk.web.meter.settings.heating',
      },
      inputOptions: null,
      radioOptions: {
        list: yesNoOptions,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'user_household_settings.uses_heat_pump',
      validationKey: 'user_household_settings.fields.uses_heat_pump',
      type: 'radio',
      label: {
        key: 'sdk.web.meter.settings.heat.pump',
      },
      inputOptions: null,
      radioOptions: {
        list: yesNoOptions,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'user_household_settings.building_type',
      validationKey: 'user_household_settings.fields.building_type',
      type: 'radio',
      label: {
        key: 'sdk.web.meter.settings.building',
        capitalizeElement: true,
      },
      inputOptions: null,
      dropdownOptions: {
        list: buildingTypeOptions,
      },
      enterKeyHint: 'next',
      dependsOn: {
        name: 'user_household_settings.uses_heat_pump',
        value: true,
        transformer: toBoolean,
      },
    },
    {
      key: 'user_household_settings.heat_pump_type',
      validationKey: 'user_household_settings.fields.heat_pump_type',
      type: 'radio',
      label: {
        key: 'sdk.web.meter.settings.heat.pump.type',
        capitalizeElement: true,
      },
      inputOptions: null,
      dropdownOptions: {
        list: heatPumpTypeOptions,
      },
      enterKeyHint: 'next',
      dependsOn: {
        name: 'user_household_settings.uses_heat_pump',
        value: true,
        transformer: toBoolean,
      },
    },
    {
      key: 'user_household_settings.energy_type',
      validationKey: 'user_household_settings.fields.energy_type',
      type: 'radio',
      label: {
        key: 'sdk.web.meter.settings.type',
        capitalizeElement: true,
      },
      inputOptions: null,
      radioOptions: {
        list: energyTypeOptions,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'energy_provider_id',
      type: 'autocomplete',
      label: {
        key: 'sdk.web.meter.settings.provider',
        capitalizeElement: true,
      },
      inputOptions: null,
      autoCompleteOptions: {
        list: energyProviders,
        onSearch: onEnergyProviderSearch,
      },
      enterKeyHint: 'next',
    },
    {
      key: 'energy_meter_id',
      type: 'text',
      label: {
        key: 'sdk.web.meter.settings.meter',
        capitalizeElement: true,
      },
      inputOptions: {},
      enterKeyHint: 'go',
    },
  ];
};
